import { AbsoluteNumber } from '@domoscio/domoscio-ui'
import React from 'react'
import { MdAlarm } from 'react-icons/md'
import I18n from '../../../../locales/I18n'
import { millisToMinutesAndSeconds } from '../../../../utils/shared'
interface Props {
  time: number
  inlineStyle?: React.CSSProperties
}
export const TimeOnResources = ({ time, inlineStyle }: Props) => {
  return (
    <div style={inlineStyle} className='componentAbsoluteNumber'>
      <AbsoluteNumber
        icon={<MdAlarm />}
        containerClassName='small_container'
        data={millisToMinutesAndSeconds(time)}
        title={I18n.t('progress.avg_total_time')}
        generalTooltipText={I18n.t('progress.tooltips.avg_total_time')}
      />
    </div>
  )
}
