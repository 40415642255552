import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'

// Sort by ObjectiveStudent array by ISO 8601 created_at
export const byObjectiveCreatedAt = (
  a: lxpHubTypes.ObjectiveStudent,
  b: lxpHubTypes.ObjectiveStudent
) => {
  if (a.objective.created_at < b.objective.created_at) return -1
  if (a.objective.created_at > b.objective.created_at) return 1
  return 0
}

// Sort by ObjectiveStudent array by objective status
export const byObjectiveStatus = (a: any, b: any) => {
  if (a.lsAId === 0 && b?.lsAId !== 0) return 1
  if (a?.lsAId !== 0 && b?.lsAId === 0) return -1
  return 0
}
