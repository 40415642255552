import React, { Dispatch, SetStateAction } from 'react'
import { MdClose } from 'react-icons/md'
import { Question } from '@domoscio/domoscio-ui'

type Props = {
  qtiContent: any
  event: any
  setScreen: Dispatch<SetStateAction<'tsr' | 'correction'>>
}

const CorrectionDisplay = ({ qtiContent, event, setScreen }: Props) => {
  const score = event?.payload?.result?.score?.scaled ?? 0

  return (
    <div className='correctionScreen'>
      <div className='header'>
        <div className='close' onClick={() => setScreen('tsr')}>
          <MdClose size={30} color='#777' />
        </div>
      </div>
      <div className='container'>
        <Question
          {...qtiContent}
          userInput={event.answer.value}
          correctionScore={score}
          isCorrectionMode={true}
        />
      </div>
    </div>
  )
}

export default CorrectionDisplay
