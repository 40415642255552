import { domoscioAccess } from '../api/domoscio_access'
import { prepareOptions } from '../utils/shared'
import { Entity, Theme, Skill, Experience, Option, ObjectivesFilters } from '../types/filter'
import { Dispatch, SetStateAction } from 'react'
import I18n from '../locales/I18n'

interface User {
  [key: string]: any
}

class NoContentError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NoContentError'
  }
}

const initFilters = (
  setEntities: (arr: Array<Entity>) => void,
  setObjectiveFilters: Dispatch<SetStateAction<ObjectivesFilters>>,
  setExperienceFilters: Dispatch<SetStateAction<Experience[]>>,
  setSelectedEntity: (arg: Option[]) => void,
  setSelectedExperience: (arg: Option[]) => void,
  setSkills: (arg: Skill[]) => void,
  handleError: (error: unknown) => void,
  user: User
) => {
  // apply filter datas
  const applyEntities = (data: Array<Entity>) => {
    //  sort data by cration date
    data = data || []
    data = data.sort((a: Entity, b: Entity) => {
      const valueA = new Date(a.created_at).getTime()
      const valueB = new Date(b.created_at).getTime()
      if (valueA < valueB) {
        return 1
      } else {
        return -1
      }
    })
    setSelectedEntity(prepareOptions(data))
    setEntities(data)
  }
  const applyObjectives = (data: Array<Theme>) => {
    setObjectiveFilters(prev => {
      return {
        ...prev,
        all: data
      }
    })
  }
  const applyExperiences = (data: Array<Experience>) => {
    //  sort data by cration date
    data = data.sort((a: Experience, b: Experience) => {
      const valueA = new Date(a.created_at).getTime()
      const valueB = new Date(b.created_at).getTime()
      if (valueA < valueB) {
        return 1
      } else {
        return -1
      }
    })
    setSelectedExperience(prepareOptions(data).slice(0, 1))
    setExperienceFilters(data)
  }
  const applySkills = (data: Array<Skill>) => {
    setSkills(data)
  }

  const isAdmin = user?.account?.employee

  // Get scoped supervisor studentGroups (unless user has_role :superadmin)
  let studentGroupUids = [] as string[]
  if (!isAdmin) {
    studentGroupUids = user?.student_groups?.map(el => el.uid) || []
  }

  // scoped learning program list for supervisor
  let learningProgramUids = [] as string[]
  if (!isAdmin) {
    learningProgramUids = user?.learning_programs?.map(el => el.uid) || []
  }

  // Fetch scoped StudentGroups details from domoscioAccess
  if (isAdmin || studentGroupUids.length > 0) {
    domoscioAccess
      .fetchStudentGroup({ uid: studentGroupUids })
      .then((response: any) => applyEntities(response))
  } else {
    handleError(new NoContentError(I18n.t('error.access')))
  }

  // Fetch scoped LearningPrograms details from domoscioAccess
  if (isAdmin || learningProgramUids.length > 0) {
    domoscioAccess
      .fetchLearningProgram({ archived: false, uid: learningProgramUids })
      .then((learningPrograms: any) => {
        if (learningPrograms != null && learningPrograms.length > 0) {
          applyExperiences(learningPrograms)
        } else {
          handleError(new NoContentError(I18n.t('error.access')))
        }
      })
  } else {
    handleError(new NoContentError(I18n.t('error.access')))
  }

  domoscioAccess.fetchObjective().then(response => applyObjectives(response))
  domoscioAccess.fetchKnowledgeNode().then(response => applySkills(response))
}

export default initFilters
