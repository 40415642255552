import * as React from 'react'
import * as ReactDOM from 'react-dom/client'

import AccessDeniedScreen from './AccessDeniedScreen'
import ErrorScreen from './ErrorScreen'
import FallbackScreen from './FallbackScreen'
import InternalErrorScreen from './InternalErrorScreen'
import NotFoundScreen from './NotFoundScreen'
import ContentUnavailableErrorScreen from './ContentUnavailableErrorScreen'

export function redirect(code: string) {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

  switch (code) {
    case '401':
      root.render(React.createElement(AccessDeniedScreen))
      break
    case '403':
      root.render(React.createElement(ContentUnavailableErrorScreen))
      break
    case '404':
      root.render(React.createElement(NotFoundScreen))
      break
    case '500':
      root.render(React.createElement(ErrorScreen))
      break
    default:
      break
  }
}

export {
  AccessDeniedScreen,
  ErrorScreen,
  FallbackScreen,
  InternalErrorScreen,
  NotFoundScreen,
  ContentUnavailableErrorScreen
}
