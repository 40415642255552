import React from 'react'
import I18n from '../../../locales/I18n'
import {
  MdOutlineGroups,
  MdOutlinePsychology,
  MdOutlineQuiz,
  MdTimer,
  MdDirectionsRun,
  MdBookmarkBorder,
  MdCheckCircleOutline,
  MdHourglassBottom,
  MdSchedule
} from 'react-icons/md'
import { millisToMinutesAndSeconds } from '../../../utils/shared'

interface Data {
  [key: string]: number | undefined
}

type cibling = 'engagement' | 'challenge'

/*
 * List of data props of AbsoluteNumber in EngagementScreenLock
 */
export const absoluteNumbersElements = (
  data: Data | undefined,
  cibling: cibling = 'engagement',
  isRiseUp?: boolean
) => {
  const container = { containerClassName: 'small_container' }
  if (cibling === 'engagement') {
    return [
      {
        ...container,
        icon: <MdOutlineGroups />,
        data: data?.nbr_students || 0,
        title: I18n.t('activity.learners', { count: data?.nbr_students || 0 }),
        generalTooltipText: I18n.t(`engagement.tooltips.learners${isRiseUp ? 'v2' : ''}`)
      },
      {
        ...container,
        icon: <MdOutlinePsychology />,
        data: data?.avg_nbr_sessions_per_student || 0,
        title: I18n.t(`engagement.review_session${isRiseUp ? 'v2' : ''}`, { count: data?.avg_nbr_sessions_per_student || 0 }),
        generalTooltipText: I18n.t(`engagement.tooltips.lock_review_session${isRiseUp ? 'v2' : ''}`)
      },
      {
        ...container,
        icon: <MdOutlineQuiz />,
        containerClassName: 'small_container',
        data: data?.avg_nbr_questions_per_student || 0,
        title: I18n.t(`engagement.answered_question${isRiseUp ? 'v2' : ''}`, { count: data?.avg_nbr_questions_per_student || 0 }),
        generalTooltipText: I18n.t(
          `engagement.tooltips.lock_answered_question${isRiseUp ? 'v2' : ''}`
        )
      },
      {
        ...container,
        icon: <MdTimer />,
        containerClassName: 'small_container',
        data: millisToMinutesAndSeconds(data?.avg_time_spent_per_student || 0),
        title: I18n.t('engagement.past'),
        generalTooltipText: I18n.t(`engagement.tooltips.lock_past${isRiseUp ? 'v2' : ''}`)
      }
    ]
  } else if (cibling === 'challenge') {
    return [
      {
        ...container,
        icon: <MdDirectionsRun />,
        data: data?.avg_actions_sent_per_student || 0,
        title: I18n.t('engagement.proposed_challenge', {
          count: data?.avg_actions_sent_per_student || 0
        }),
        generalTooltipText: I18n.t('engagement.tooltips.lock_proposed_challenge')
      }
    ]
  } else {
    return []
  }
}

/*
 * List of data props of Percentage in EngagementScreenLock
 */
export const percentageElements = (
  data: Data | undefined,
  cibling: cibling = 'engagement',
  isRiseUp?: boolean
) => {
  const container = { containerClassName: 'medium_container' }
  const hidden = { containerClassName: 'hidden' }
  if (cibling === 'engagement') {
    const hasObjectiveContext =
      // @ts-ignore
      data?.objective && data?.objective.length > 0 && !['all', 'no'].includes(data.objective[0].id)
    return [
      {
        ...container,
        progress: data?.perc_students_up_to_date || 0,
        isZero: data?.perc_students_up_to_date === 0,
        title: I18n.t('engagement.learners_update'),
        generalTooltipText: I18n.t(`engagement.tooltips.learners_update${isRiseUp ? 'v2' : ''}`),
        icon: <MdCheckCircleOutline />,
        isDynamicIcon: false,
        iconColor: isRiseUp ? '#8290B4' : '#32D296'
      },
      {
        ...container,
        progress: data?.perc_students_pending || 0,
        isZero: data?.perc_students_pending === 0,
        title: I18n.t(`engagement.learners_have_reco${isRiseUp ? 'v2' : ''}`),
        generalTooltipText: I18n.t(`engagement.tooltips.learners_have_reco${isRiseUp ? 'v2' : ''}`),
        icon: <MdHourglassBottom size={22} />,
        isDynamicIcon: false,
        iconColor: isRiseUp ? '#8290B4' : '#FF8413'
      },
      {
        ...container,
        progress: data?.perc_students_late || 0,
        isZero: data?.perc_students_late === 0,
        title: I18n.t(`engagement.learners_overdue${isRiseUp ? 'v2' : ''}`),
        generalTooltipText: I18n.t(`engagement.tooltips.learners_overdue${isRiseUp ? 'v2' : ''}`),
        icon: <MdSchedule />,
        isDynamicIcon: false,
        iconColor: isRiseUp ? '#8290B4' : '#FF8413'
      },
      {
        ...(hasObjectiveContext || isRiseUp ? hidden : container),
        progress: data?.perc_objectives_started || 0,
        isZero: data?.perc_objectives_started === 0,
        title: I18n.t('engagement.book_started'),
        generalTooltipText: I18n.t('engagement.tooltips.lock_book_started'),
        icon: <MdBookmarkBorder />,
        isDynamicIcon: false,
        iconColor: isRiseUp ? '#8290B4' : '#32D296'
      }
    ]
  } else if (cibling === 'challenge') {
    return [
      {
        ...container,
        progress: data?.perc_actions_accepted || 0,
        isZero: data?.perc_actions_accepted === 0,
        title: I18n.t('engagement.accepted_challenges'),
        generalTooltipText: I18n.t('engagement.tooltips.lock_accepted_challenges'),
        icon: <MdDirectionsRun />,
        isDynamicIcon: false,
        iconColor: 'rgb(143, 155, 186)'
      },
      {
        ...container,
        progress: data?.perc_students_actions_in_progress || 0,
        isZero: data?.perc_students_actions_in_progress === 0,
        title: I18n.t('engagement.ongoing_challenges'),
        generalTooltipText: I18n.t('engagement.tooltips.lock_ongoing_challenges'),
        icon: <MdHourglassBottom />,
        isDynamicIcon: false,
        iconColor: '#32D296'
      }
    ]
  } else {
    return []
  }
}
