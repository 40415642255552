import { DatePicker, FilterMenu } from '@domoscio/domoscio-ui'
import React, { useContext, useEffect } from 'react'
import {
  EntityFilterContext,
  ExperiencesFilterContext,
  ObjectivesFilterContext,
  DatesFilterContext
} from '../../contexts/parameters/filterContext'
import I18n from '../../locales/I18n'
import './StatFilters.css'
import { useLocation } from 'react-router-dom'
import {
  getThemesOfExperience,
  prepareLearningProgramGroups,
  prepareOptions,
  prepareThemes
} from '../../utils/shared'
import { Client } from '../../contexts/parameters'
import ExportButton from './subComponents/ExportButton'
import { domoscioAccess } from '../../api/domoscio_access'

interface StatFiltersProps {
  onInit?(arg: any): void
}

const StatFilters: React.FC<StatFiltersProps> = ({ onInit = () => {} }) => {
  //  get context values for entities, objectives and experiennces
  const {
    entityFilters: entities,
    setSelectedEntity,
    selectedEntity
  } = useContext(EntityFilterContext)
  const {
    experienceFilters: experiences,
    selectedExperience,
    setSelectedExperience
  } = useContext(ExperiencesFilterContext)
  const {
    objectiveFilters: objectives,
    selectedObjective,
    setSelectedObjective,
    setObjectiveFilters
  } = useContext(ObjectivesFilterContext)

  const { setFilteredDates, filteredDates } = useContext(DatesFilterContext)
  //  get mode hub| lock,
  const client = React.useContext(Client.State)
  const mode = client?.product || 'hub'

  // don't display themes filter on usage and activity pages
  const location: any = useLocation()
  const pathname = location.pathname
  const displayExperienceFilter = true
  const displayEntityFilter = true
  const displayThemeFilter = pathname === '/' || pathname === '/engagement'

  const getExpCreationDate = () => {
    const selectedExperienceId = selectedExperience[0]?.id
    const exp = experiences.filter(exp => exp.id === selectedExperienceId)?.[0]
    const startAt = exp?.legacy_start || exp?.created_at
    return startAt
  }

  useEffect(() => {
    const setDates = () => {
      // setDateRange by using creation date of experience
      setFilteredDates(() => [new Date(getExpCreationDate()), new Date()])
    }
    if (selectedExperience.length > 0 && experiences.length > 0) {
      setDates()
    }
  }, [selectedExperience, experiences])

  useEffect(() => {
    const initObjectives = () => {
      // if prev page is userDetails don't exec useEffect for theme select
      const fromDetails = location?.state?.from === 'userDetails'

      // we will find all themes of selected experince,
      // 🚨 we need to ensure that we have  exp and objectives
      if (objectives.all.length > 0 && experiences.length > 0 && !fromDetails) {
        const filteredThemes = getThemesOfExperience(
          experiences,
          objectives.all,
          selectedExperience
        )

        // on hub by default select first theme of selected experience, on lock select all themes
        const selectedTheme =
          prepareThemes(filteredThemes).length === 1 || mode === 'lock'
            ? prepareThemes(filteredThemes).slice(0, 1)
            : prepareThemes(filteredThemes).slice(1, 2)

        setSelectedObjective(selectedTheme)
        setObjectiveFilters(prev => {
          return {
            ...prev,
            objectivesOfSelectedExp: filteredThemes
          }
        })
      }
    }
    initObjectives()
  }, [selectedExperience, mode, objectives.all, experiences])

  const switchSelectedIdExperience = () => {
    if (selectedExperience.length > 0) {
      return selectedExperience.slice(0, 1)
    } else {
      return prepareOptions(experiences).slice(0, 1)
    }
  }
  /*
   * Return each filters display & ready status
   */
  const loadingFilter = () => {
    return {
      entities: { display: displayEntityFilter, isReady: entities.length > 1 },
      experiences: { display: displayExperienceFilter, isReady: experiences.length > 0 },
      objectives: {
        display: displayThemeFilter,
        isReady:
          objectives.all &&
          objectives.all.length > 0 &&
          selectedObjective &&
          selectedObjective.length > 0
      }
    }
  }

  // Prepare displayFilter function conditional
  const filters = loadingFilter()
  const displayFilter = (key: any) => filters[key].display && filters[key].isReady

  // On first mount -> apply onInit props function callback
  useEffect(() => {
    onInit(loadingFilter)
  }, [])

  const handleDatePickerClick = arg => {
    // kpi logs (feature tracking)
    domoscioAccess.postKpiLog('SupervisorDateFilter')

    setFilteredDates(arg)
  }

  return (
    <div className='statFiltersWrapper'>
      <div className='statFilters step2'>
        {displayFilter('entities') && (
          <FilterMenu
            type='multi'
            options={prepareOptions(entities)}
            label={I18n.t('screen.entity')}
            size='md'
            selectAll={true}
            search={true}
            defaultSelectedList={selectedEntity}
            onSelectCompleted={arg => {
              setSelectedEntity(arg)
            }}
            tooltip={true}
          />
        )}
        {displayFilter('experiences') && (
          <FilterMenu
            type='single'
            options={prepareOptions(experiences)}
            groups={prepareLearningProgramGroups(experiences)}
            label={I18n.t('screen.experience')}
            size='md'
            search={true}
            defaultSelectedList={switchSelectedIdExperience()}
            onSelectCompleted={arg => {
              setSelectedExperience(arg)
            }}
            tooltip={true}
          />
        )}
        {displayFilter('objectives') && (
          <FilterMenu
            type='single'
            options={prepareThemes(objectives.objectivesOfSelectedExp)}
            label={I18n.t('screen.theme')}
            search={true}
            defaultSelectedList={selectedObjective.slice(0, 1)}
            size='md'
            onSelectCompleted={arg => setSelectedObjective(arg)}
            tooltip={true}
          />
        )}
        {displayFilter('experiences') && filteredDates.length > 1 && (
          <>
            <DatePicker
              label={I18n.t('screen.date_registration_experience')}
              onSelectCompleted={handleDatePickerClick}
              rangeEndDate={filteredDates[1]}
              rangeStartDate={filteredDates[0]}
              type='range'
              maxDate={new Date()}
              minDate={new Date(getExpCreationDate())}
            />
            <ExportButton />
          </>
        )}
      </div>
    </div>
  )
}

export default StatFilters
