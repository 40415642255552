import React, { ReactComponentElement } from 'react'
import { IconType } from 'react-icons'
import { useDomToolkit, Style } from '../../styles/dom-toolkit'
import './TitleScreen.scss'

const getStyles = (classes: any) =>
  Style.create({
    titleScreenContainer: [classes.domFlexLeft, classes.domMarginLargeVertical],
    titleScreen: [classes.domText18, classes.domTextSecondary],
    iconContainer: [classes.domBgPrimary, classes.domMarginLeft],
    icon: [classes.domFlexPerfect, classes.domTextWhite, classes.domText20]
  })

export interface ITitleScreenProps {
  titleScreen: string
  icon?: ReactComponentElement<IconType> | null
}
const TitleScreen = ({ titleScreen, icon }: ITitleScreenProps) => {
  const classes: any = useDomToolkit()
  const styles = getStyles(classes)
  return (
    <div className={`TitleContainer ${styles.titleScreenContainer}`}>
      <h2 className={styles.titleScreen}>{titleScreen}</h2>
      {icon && (
        <div className={`BorderRadiusCircle ${styles.iconContainer}`}>
          <span className={styles.icon}>{icon}</span>
        </div>
      )}
    </div>
  )
}

export default TitleScreen
