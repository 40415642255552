import { ListingCards, NoResult, ThemeCard } from '@domoscio/domoscio-ui'
import React, { useMemo, useState } from 'react'
import { lxpHubTypes, lxpHubUtils } from '@domoscio/domoscio-sdk-js'
import I18n from '../../../../../locales/I18n'
import './LearningProgramShow.scss'
import { useStudentDatas } from '../../StudentScreenHub'
import { NavLink } from 'react-router-dom'
import { byObjectiveStatus } from './utils'

const LearningProgramShow = () => {
  // Filters
  const [filters, setFilters] = useState({
    search: '',
    status: 'all'
  })

  const { objectivesStudent, isOts, primaryColor } = useStudentDatas()

  // Sort by ObjectiveStudent array by ISO 8601 created_at
  const byObjectiveId = (a: lxpHubTypes.ObjectiveStudent, b: lxpHubTypes.ObjectiveStudent) => {
    if (a.objective.id < b.objective.id) return -1
    if (a.objective.id > b.objective.id) return 1
    return 0
  }

  const themeCardDatas = objectivesStudent
    .sort(byObjectiveId)
    .map(os => lxpHubUtils.prepareThemeCardDatas(os as any, true))
    .sort(byObjectiveStatus)

  const inputSelectOptions = useMemo(() => lxpHubUtils.createFilterInputs(themeCardDatas), [])

  const filteredList = themeCardDatas.filter(elem => {
    if (filters.status === 'all') {
      return lxpHubUtils.isTextMatches(filters.search, elem.title)
    } else {
      return (
        elem.statusText.id === filters.status &&
        lxpHubUtils.isTextMatches(filters.search, elem.title)
      )
    }
  })

  return (
    <div className='learningProgramShow'>
      {/* Objectives */}
      <div className='heading'>
        {/* Title */}
        <div className='title'>
          <span>{I18n.t('screen.all_themes')}</span>
        </div>
        {/* Filters */}
        <ListingCards
          filters={filters}
          onChange={setFilters}
          inputSelectOptions={inputSelectOptions}
        />
      </div>
      <div className='objectives'>
        {filteredList.length === 0 ? (
          <NoResult />
        ) : (
          <div className='themeList'>
            {filteredList.map((objective, index) => (
              <NavLink
                key={index}
                to={objective.lsAId ? `/student/objective/${objective.objectiveId}/adaptive` : '/'}
                style={{
                  pointerEvents: objective.isClickable ? 'auto' : 'none'
                }}
              >
                <div className={'cardContainer'}>
                  <ThemeCard
                    cardStatus={objective.cardStatus}
                    statusText={objective.statusText}
                    primaryColor={primaryColor}
                    progress={objective.progress}
                    title={objective.title}
                    timeInfo={objective.timeInfo}
                    subInfos={objective.subInfos}
                    isOts={isOts}
                    isHighLighted={false}
                    imageUrl={objective.imageUrl}
                  />
                </div>
              </NavLink>
            ))}
            {[...Array(3 - (filteredList.length % 3))].map((_, index) => {
              return <div key={index} className='emptyCard'></div>
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default LearningProgramShow
