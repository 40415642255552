import React, { useContext, useEffect } from 'react'
import { MdOutlineCheckCircle, MdOutlineErrorOutline } from 'react-icons/md'
import './ExportDownload.scss'
import I18n from '../../../locales/I18n'
import { Session } from '../../../contexts/parameters'
import { getHeadersFromUrl, getTimeFromAwsTimeFormat } from './utils'
import { domUtils } from '@domoscio/domoscio-sdk-js'

const ExportDownload = () => {
  const session = useContext(Session.State)
  const downloadLink = session.options.lxp.presigned_file_url

  // header list of s3 download link 👉 https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
  const urlHeaders = getHeadersFromUrl(downloadLink)

  /*
  We wiil use to header
  X-Amz-Date => link creation date in format "20160801T223241Z"
  X-Amz-Expires	=> Provides the time period, in seconds, for which the generated presigned URL is valid, Ex: "600" is 600 secons
  */
  const linkCreatedAt = urlHeaders['X-Amz-Date'] // format "20160801T223241Z"
  const validPeriodAfterCreation = urlHeaders['X-Amz-Expires'] // in seconds
  const validPeriodAfterCreationMilliseconds = validPeriodAfterCreation * 1000 // 1 seconds = 1000 milliseconds
  const formattedDate = getTimeFromAwsTimeFormat(linkCreatedAt) // formats aws time to UTC
  // calculates expiration time of link time + expiration periode
  const linkExpiresAt = new Date(
    new Date(formattedDate).getTime() + validPeriodAfterCreationMilliseconds
  )

  // date comparison
  const isLinkExpired = linkExpiresAt < new Date()

  const homePageUrl = domUtils.addhttp(session?.mqb_url)

  const returnToHome = () => (window.location.href = homePageUrl)

  const startDownload = () => window.open(downloadLink)

  useEffect(() => {
    document.title = 'Export Domoscio'
    let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.head.appendChild(link)
    }
    link.href =
      'https://gitlab.com/domoscio-team/domoscio/front/analytics/-/raw/preprod/public/export.png?ref_type=heads'
    if (!isLinkExpired) {
      // launches export download
      startDownload()
    }
    sessionStorage.setItem('homePageUrl', homePageUrl)
    sessionStorage.removeItem('accesstoken')
  }, [])

  return (
    <div className='exportDownload'>
      {isLinkExpired ? (
        <MdOutlineErrorOutline style={{ color: '#ed7f7f' }} size={100} />
      ) : (
        <MdOutlineCheckCircle style={{ color: '#50c58f' }} size={100} />
      )}
      <h2>{I18n.t(`export.download.${isLinkExpired ? 'linkExpired' : 'ready'}.title`)}</h2>
      <p>
        {I18n.t(`export.download.${isLinkExpired ? 'linkExpired' : 'ready'}.body`)}{' '}
        {!isLinkExpired && (
          <a href={downloadLink} download>
            {I18n.t(`export.download.ready.click_here`)}
          </a>
        )}
      </p>
      {isLinkExpired && (
        <button onClick={returnToHome}>{I18n.t('export.download.linkExpired.connect')}</button>
      )}
    </div>
  )
}

export default ExportDownload
