import { IconType } from 'react-icons'
import { MdOutlineQuestionAnswer, MdAccessTime } from 'react-icons/md'
import { FaRegStar } from 'react-icons/fa'
import { lxpHubUtils, lxpHubTypes } from '@domoscio/domoscio-sdk-js'
import I18n from '../../../../../locales/I18n'

type Answer = {
  data: string
  icon: IconType
  title: string
}

export const getBadgeDatas = (
  learningSession: lxpHubTypes.LearningSession | undefined,
  objectiveStudent: lxpHubTypes.ObjectiveStudent
) => {
  const options = objectiveStudent?.objective?.adaptive_options || {}
  const isSelfPositioning = options.positioning_type === 'SelfPosSelection'

  const skills = objectiveStudent?.objective?.validation_thresholds?.knowledge_node_ids
  const skillCount = skills ? Object.keys(skills).length : 0

  const timeSpent = lxpHubUtils.msToTime(learningSession?.time_spent || 0)
  const totalQuestion = learningSession?.interaction_count || 0
  // const falseAnswers = learningSession?.error_count || 0
  const correctAnswers = learningSession?.success_count || 0

  const correctResponseRatio = correctAnswers + '/' + totalQuestion
  const validatedSkills =
    (learningSession?.nbr_kn_validated?.toString() || '0') + '/' + skillCount?.toString()

  const answers: Answer[] = [
    {
      data: correctResponseRatio,
      icon: MdOutlineQuestionAnswer,
      title: I18n.t('lxp_hub.positioning_result.cards.correct_answers', {
        count: correctAnswers || 1
      })
    }
  ]

  return [
    {
      data: timeSpent,
      icon: MdAccessTime,
      title: I18n.t('lxp_hub.positioning_result.cards.time_spent')
    },
    ...(isSelfPositioning ? [] : answers),
    {
      data: validatedSkills.toString(),
      icon: FaRegStar,
      title: I18n.t('lxp_hub.positioning_result.cards.validated_skills', {
        count: learningSession?.nbr_kn_validated || 1
      })
    }
  ]
}
