import React, { createContext, useState } from 'react'
import I18n from '../../locales/I18n'
import { FilterBodyContextProvider } from './filterBodyContext'
import {
  Entity,
  ObjectivesFilters,
  Skill,
  Experience,
  EntityContext,
  ExperienceContext,
  ObjectiveContext,
  SkillContext,
  Option,
  DatePickerContext
} from '../../types/filter'

interface AuxProps {
  children: any
}

// Entity Context
const defaultEntityContext = {
  entityFilters: [],
  setEntities: () => {},
  selectedEntity: [],
  setSelectedEntity: () => {}
}

const EntityFilterContext = createContext<EntityContext>(defaultEntityContext)

const EntityFilterContextProvider = ({ children }: AuxProps) => {
  const [entityFilters, setEntityFilters] = useState<Array<Entity>>([])
  const [selectedEntity, setSelectedEntity] = useState<Option[]>([])
  const setEntities = (arr: Array<Entity>) => {
    // reorder entities
    const indexOfDefaultGroup = arr.findIndex(elem => elem?.default_group) // return -1 if there isn't default group

    // if index === -1 there is no default group than don't change name
    if (indexOfDefaultGroup !== -1) {
      const deletedElement = arr.splice(indexOfDefaultGroup, 1)

      if (deletedElement.length > 0) {
        deletedElement[0].name = I18n.t('screen.learner_without_entity')
        arr.unshift(deletedElement[0])
      }
    }
    setEntityFilters(arr)
  }

  const value = { entityFilters, setEntities, selectedEntity, setSelectedEntity }
  return <EntityFilterContext.Provider value={value}>{children}</EntityFilterContext.Provider>
}

// Objective Context
const defaultObjectiveContext = {
  objectiveFilters: {
    all: [],
    objectivesOfSelectedExp: []
  },
  setObjectiveFilters: () => {},
  selectedObjective: [],
  setSelectedObjective: () => {}
}
const ObjectivesFilterContext = createContext<ObjectiveContext>(defaultObjectiveContext)

const ObjectivesFilterContextProvider = ({ children }: AuxProps) => {
  const [objectiveFilters, setObjectiveFilters] = useState<ObjectivesFilters>({
    all: [],
    objectivesOfSelectedExp: []
  })
  const [selectedObjective, setSelectedObjective] = useState<Option[]>([])

  const value = { objectiveFilters, setObjectiveFilters, selectedObjective, setSelectedObjective }
  return (
    <ObjectivesFilterContext.Provider value={value}>{children}</ObjectivesFilterContext.Provider>
  )
}

// Experience Context
const defaultExperienceContext = {
  experienceFilters: [],
  setExperienceFilters: () => {},
  selectedExperience: [],
  setSelectedExperience: () => {}
}
const ExperiencesFilterContext = createContext<ExperienceContext>(defaultExperienceContext)

const ExperiencesFilterContextProvider = ({ children }: AuxProps) => {
  const [experienceFilters, setExperienceFilters] = useState<Array<Experience>>([])
  const [selectedExperience, setSelectedExperience] = useState<Option[]>([])

  const value = {
    experienceFilters,
    setExperienceFilters,
    selectedExperience,
    setSelectedExperience
  }
  return (
    <ExperiencesFilterContext.Provider value={value}>{children}</ExperiencesFilterContext.Provider>
  )
}

// Skill Context
const defaultSkillContext = {
  skills: [],
  setSkills: () => {}
}
const SkillsContext = createContext<SkillContext>(defaultSkillContext)

const SkillsContextProvider = ({ children }: AuxProps) => {
  const [skills, setSkills] = useState<Array<Skill>>([])
  const value = {
    skills,
    setSkills
  }
  return <SkillsContext.Provider value={value}>{children}</SkillsContext.Provider>
}

// DatePicker Context
const defaultDatePickerContext = {
  filteredDates: ['', ''],
  setFilteredDates: () => {}
}
const DatesFilterContext = createContext<DatePickerContext>(defaultDatePickerContext)

const DatesFilterContextProvider = ({ children }: AuxProps) => {
  const [filteredDates, setFilteredDates] = useState<Array<Date> | null>([])
  const value = {
    filteredDates,
    setFilteredDates
  }
  return <DatesFilterContext.Provider value={value}>{children}</DatesFilterContext.Provider>
}

// filters ProviderWrapper
const FilterContextProvider = ({ children }: AuxProps) => {
  return (
    <>
      <EntityFilterContextProvider>
        <>
          <ObjectivesFilterContextProvider>
            <>
              <ExperiencesFilterContextProvider>
                <>
                  <SkillsContextProvider>
                    <>
                      <DatesFilterContextProvider>
                        <>
                          <FilterBodyContextProvider>
                            <>{children}</>
                          </FilterBodyContextProvider>
                        </>
                      </DatesFilterContextProvider>
                    </>
                  </SkillsContextProvider>
                </>
              </ExperiencesFilterContextProvider>
            </>
          </ObjectivesFilterContextProvider>{' '}
        </>
      </EntityFilterContextProvider>
    </>
  )
}

export {
  FilterContextProvider,
  ExperiencesFilterContext,
  ObjectivesFilterContext,
  EntityFilterContext,
  SkillsContext,
  DatesFilterContext
}
