import React, { useLayoutEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { Loader } from '@domoscio/domoscio-ui'
import * as Errors from '../../../components/Errors'
import { Theme } from '../../../contexts/parameters'
import {
  ExperiencesFilterContext,
  ObjectivesFilterContext
} from '../../../contexts/parameters/filterContext'

import './StudentScreenHub.scss'
import { useFetchStudentLearningDatas } from '../../../hooks/useFetchStudentDatas'
import TrailNavigation from './subComponents/TrailNavigation/TrailNavigation'
import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'

const StudentScreenHub = () => {
  const location = useLocation()
  const [user] = useState(() => ({
    name: location.state?.student_name || location.state?.student_uid,
    uid: location.state?.student_uid
  }))
  const [backButtonUrl] = useState(() => location.state?.from)

  const theme = React.useContext(Theme.State)
  const { selectedObjective } = React.useContext(ObjectivesFilterContext)
  const { experienceFilters, selectedExperience } = React.useContext(ExperiencesFilterContext)

  const lp = experienceFilters.find(exp => exp.id === selectedExperience[0].id)

  const primaryColor = theme?.domBgPrimary
  const objectiveId = selectedObjective[0].id
  const objectiveIds = typeof objectiveId === 'number' ? [objectiveId] : null
  const navigate = useNavigate()

  const { objectivesStudent, isLoading } = useFetchStudentLearningDatas(user.uid, objectiveIds)

  // 🚨 if we don't have uid of learner => redirect user to home page
  useLayoutEffect(() => {
    if (!location.state?.student_uid) {
      navigate('/')
    }
  }, [])

  if (!user.uid) {
    return <Errors.ErrorScreen fill={primaryColor} />
  }
  if (isLoading) {
    return <Loader></Loader>
  }

  const isOts = lp?.is_ots
  return (
    <div className='studentScreenHub'>
      <TrailNavigation
        backButtonUrl={backButtonUrl}
        primaryColor={isOts ? '#6951E0' : primaryColor}
        userName={user.name}
      />
      <Outlet context={{ primaryColor, objectivesStudent, selectedObjective, isOts }} />
    </div>
  )
}

export default StudentScreenHub

type ContextType = {
  primaryColor: string
  objectivesStudent: lxpHubTypes.ObjectiveStudent[]
  selectedObjective: any
  isOts: boolean
}

export function useStudentDatas() {
  return useOutletContext<ContextType>()
}
