import React from 'react'
import * as ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import './styles/index.css'
import App from './App'
import reportWebVitals from './core/reportWebVitals'
import packageConfig from '../package.json'

const env = process.env.REACT_APP_DOMOSCIO_ENV || 'dev'
if (env !== 'dev') {
  Sentry.init({
    dsn: 'https://bc969a66643843ca86b4f240bd135419@o1022181.ingest.sentry.io/6350427',
    normalizeDepth: 10,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay({
        blockAllMedia: true,
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [/domoscio/]
      })
    ],

    ignoreErrors: ['NoContentError', 'change_ua', 'Non-Error exception captured'],
    tracesSampleRate: 1.0,
    environment: env,
    release: 'analytics@' + packageConfig?.version || 'dev',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  })
}
// default options for all queryies to see more => https://tanstack.com/query/v4/docs/react/reference/QueryClient
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 4 * (60 * 1000), // 10 mins
      cacheTime: 20 * (60 * 1000) // 20 mins
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ReactQueryDevtools
        initialIsOpen={false}
        panelProps={{ style: { height: 400, width: 1000 } }}
        position='bottom-right'
      />
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
