import React from 'react'
import I18n from '../../../locales/I18n'
import { MdOutlineWhereToVote, MdOutlineLibraryBooks, MdOutlineTurnedInNot } from 'react-icons/md'

// all props of all percentage components
export const prepareHubEngagementData = (data: any, isThemeSelected: boolean) => {
  return [
    {
      progress: data?.perc_students_first_positioning || 0,
      containerClassName: 'medium_container',
      title: I18n.t('engagement.hub_learner_done_initial_quiz'),
      generalTooltipText: I18n.t('engagement.tooltips.hub_learner_done_initial_quiz'),
      icon: <MdOutlineWhereToVote />,
      isDynamicIcon: true,
      isZero: data?.perc_students_first_positioning === 0
    },
    {
      progress: data?.perc_students_one_recommendation || 0,
      containerClassName: 'medium_container',
      title: I18n.t('engagement.hub_learner_followed_at_least_one_reco'),
      generalTooltipText: I18n.t('engagement.tooltips.hub_learner_followed_at_least_one_reco'),
      icon: <MdOutlineLibraryBooks />,
      isDynamicIcon: true,
      isZero: data?.perc_students_one_recommendation === 0
    },
    {
      progress: data?.perc_recommendations_seen || 0,
      containerClassName: 'medium_container',
      title: I18n.t('engagement.reco_clicked'),
      generalTooltipText: I18n.t('engagement.tooltips.hub_reco_clicked'),
      icon: <MdOutlineTurnedInNot />,
      isDynamicIcon: true,
      isZero: data?.perc_recommendations_seen === 0
    },
    {
      progress: data?.perc_students_finished_learning_program || 0,
      containerClassName: 'medium_container',
      title: !isThemeSelected
        ? I18n.t('engagement.hub_learner_finished_exp')
        : I18n.t('engagement.hub_learner_finished_theme'),
      generalTooltipText: !isThemeSelected
        ? I18n.t('engagement.tooltips.hub_learner_finished_exp')
        : I18n.t('engagement.tooltips.hub_learner_finished_theme'),
      icon: <MdOutlineWhereToVote />,
      isDynamicIcon: true,
      isZero: data?.perc_students_finished_learning_program === 0
    }
  ]
}
