import { Skill, Theme } from '../../../types/filter'
import { millisToMinutesAndSeconds } from '../../../utils/shared'

const getLearnersCount = (data: any) => {
  return data?.nbr_student || 0
}
const getSkillsCount = (data: any) => {
  return data?.nbr_knowledge_nodes || 0
}

const getAvgPositionings = (data: any) => {
  return data?.avg_nbr_positionings || 0
}

const getDetailsBySkill = (data: any, skills: Skill[]) => {
  const knowledgeNodesOfTheme = data?.knowledge_nodes || []

  // find and add kn name
  return knowledgeNodesOfTheme.map(elem => {
    const label = skills.filter(skill => skill.uid === elem.uid)[0]?.name
    return { ...elem, label: label }
  })
}

const getMultiGraphDatas = (data: any, skills: Skill[]) => {
  return getDetailsBySkill(data, skills).map(elem => {
    return { label: elem?.label || '', value: elem?.values?.perc_students_validation || 0 }
  })
}

const getTimePerPositionning = (time: number) => {
  return millisToMinutesAndSeconds(time)
}

// 🚨 Radar data
const getSkillsOfSelectedObjective = (data: any, skills: Skill[], selectedTheme: Theme) => {
  // 👇 we don't have validation level data for all themes than
  //  step 1 => verify we have a validation level for all themes
  if (data?.knowledge_nodes) {
    // get skills of theme
    const skillsOfTheme = getDetailsBySkill(data, skills)
    const scale = selectedTheme?.nb_levels ? selectedTheme?.nb_levels : 5 // 5 is default scale  for radar
    let isDisplayFinalLevel = false
    let isDisplayInitLevel = false
    const isTherotical = selectedTheme.attempt_options?.workflow === null

    const radarData = skillsOfTheme.map((skill, index) => {
      // find name of skill from skills array
      const nameOfSkill = skill.label
      // calculte initial, actual and target levels

      const initialLevel =
        (((data?.knowledge_nodes[index]?.values?.avg_kn_levels.initial_level === null
          ? -4
          : data?.knowledge_nodes[index]?.values?.avg_kn_levels.initial_level) +
          4) *
          (scale + 1)) /
        8

      if (data?.knowledge_nodes[index]?.values?.avg_kn_levels.initial_level) {
        isDisplayInitLevel = true
      }
      let actualLevel = null as null | number

      if (isTherotical) {
        actualLevel =
          (((data?.knowledge_nodes[index]?.values?.avg_kn_levels.estimated_level === null
            ? -4
            : data?.knowledge_nodes[index]?.values?.avg_kn_levels.estimated_level) +
            4) *
            (scale + 1)) /
          8
      } else {
        actualLevel =
          (((data?.knowledge_nodes[index]?.values?.avg_kn_levels.final_level === null
            ? -4
            : data?.knowledge_nodes[index]?.values?.avg_kn_levels.final_level) +
            4) *
            (scale + 1)) /
          8
      }

      if (
        data?.knowledge_nodes[index]?.values?.avg_kn_levels?.final_level ||
        data?.knowledge_nodes[index]?.values?.avg_kn_levels?.estimated_level
      ) {
        isDisplayFinalLevel = true
      }

      const targetLevel =
        (((data?.knowledge_nodes[index]?.values?.avg_kn_levels.threshold_level === null
          ? -4
          : data?.knowledge_nodes[index]?.values?.avg_kn_levels.threshold_level) +
          4) *
          (scale + 1)) /
        8
      return {
        subject: nameOfSkill,
        initialLevel,
        actualLevel,
        targetLevel
      }
    })
    return {
      scale,
      radarData,
      isDisplayFinalLevel,
      isDisplayInitLevel,
      isTherotical
    }
  } else {
    // no data for this theme
    return {
      radarData: [],
      isDisplayFinalLevel: true,
      isDisplayInitLevel: true
    }
  }
}

const getBarComparisonData = (data: any, graph?: string) => {
  let progress1 = 0
  let progress2 = 0

  if (data) {
    if (graph === 'answeredQuestions') {
      progress1 = data?.avg_first_positioning_nbr_questions
      progress2 = data?.avg_last_positioning_nbr_questions
    } else {
      progress1 = data?.avg_first_positioning_time
      progress2 = data?.avg_last_positioning_time
    }
  }
  return [
    { progress: progress1, legend: 'Initial' },
    { progress: progress2, legend: 'Final' }
  ]
}

const getPercComparisonData = (data: any, graph?: string) => {
  let progress1 = 0
  let progress2 = 0
  if (data) {
    if (graph === 'correctAnswers') {
      progress1 = data?.avg_first_positioning_perc_correct_answers
      progress2 = data?.avg_last_positioning_perc_correct_answers
    } else {
      progress1 = data?.avg_first_positioning_perc_validated_kn
      progress2 = data?.avg_last_positioning_perc_validated_kn
    }
  }

  return {
    innerCircle: {
      progress: progress1,
      legend: 'Initial'
    },
    outerCircle: {
      progress: progress2,
      legend: 'Final'
    }
  }
}

export {
  getTimePerPositionning,
  getLearnersCount,
  getAvgPositionings,
  getDetailsBySkill,
  getMultiGraphDatas,
  getSkillsOfSelectedObjective,
  getPercComparisonData,
  getBarComparisonData,
  getSkillsCount
}
