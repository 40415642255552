import React, { useContext } from 'react'
import './TrailNavigation.scss'
import { MdArrowBack, MdChevronRight } from 'react-icons/md'
import I18n from '../../../../../locales/I18n'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ExperiencesFilterContext,
  ObjectivesFilterContext
} from '../../../../../contexts/parameters/filterContext'

type Props = {
  primaryColor: string
  userName: string
  backButtonUrl: string
}

const TrailNavigation = ({ primaryColor, userName, backButtonUrl }: Props) => {
  const location = useLocation()

  const { selectedExperience } = useContext(ExperiencesFilterContext)
  const experience = selectedExperience[0].value
  const navigate = useNavigate()
  const params = useParams()
  const { objectiveFilters, selectedObjective } = React.useContext(ObjectivesFilterContext)
  const isObjectiveScreen = location.pathname.includes('objective')

  const handleClickBackBtn = () => {
    if (isObjectiveScreen && selectedObjective[0].id === 'all') {
      navigate(`/student/learning_programs/${selectedExperience[0].id}`)
    } else {
      navigate(backButtonUrl)
    }
  }
  const otherInfos = [experience]
  if (isObjectiveScreen) {
    const objectiveName = objectiveFilters.objectivesOfSelectedExp.find(
      objective => objective.id === Number(params.id)
    )?.name
    otherInfos.push(objectiveName || 'not found')
  }
  return (
    <div className='trailNavigation'>
      <div className='user'>
        <div
          onClick={handleClickBackBtn}
          style={{ backgroundColor: primaryColor }}
          className='backButton'
        >
          <MdArrowBack color='white' size={10} />
        </div>
        <div className='userTitle'>{I18n.t('studentScreen.user')} :</div>
        <div className='userName'>{userName}</div>
      </div>
      <div className='rightNav'>
        {otherInfos.map((elem, index) => (
          <div key={index} className='infoLineWrapper'>
            <div className='otherInfos'>
              <div style={{ color: primaryColor }} className='trailArrow'>
                <MdChevronRight />
              </div>
              <div className='userTitle'>
                {I18n.t(`screen.${index === 0 ? 'experience' : 'theme_single'}`)} :
              </div>
              <div style={{ color: primaryColor }} className='infoName'>
                {elem}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TrailNavigation
