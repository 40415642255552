/**
 * @file Manages the methods of the HomeNav component.
 */

import React from 'react'
import I18n from '../../locales/I18n'
import { useNavigate, useMatch, useResolvedPath } from 'react-router-dom'
import { useDomToolkit, Style } from '../../styles/dom-toolkit'
import { MdShowChart, MdPersonOutline, MdDataUsage } from 'react-icons/md'
import './HomeNav.scss'
import { postTabUsageKpis } from './utils'

const getStyles = (classes: any) =>
  Style.create({
    HomeNavLink: ['HomeNavSmallLink', classes.domMarginLeft, classes.domMarginRight],
    HomeNav: [classes.domFlex, classes.domFlex2, classes.domFlexCenter, classes.domMarginTop]
  })

/**
 * Renders the HomeNavLink
 */
function HomeNavLink({ children, to, ...props }: any) {
  const classes: any = useDomToolkit()
  const styles = getStyles(classes)
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })
  const navigate = useNavigate()

  const handleClick = () => {
    postTabUsageKpis(to)
    navigate(to)
  }
  return (
    <div className={styles.HomeNavLink}>
      <div
        className={match !== null ? 'link ' + classes.domTextPrimary : 'link'}
        onClick={handleClick}
      >
        {children}
      </div>
    </div>
  )
}

/**
 * Renders the Home Navigation
 *
 * @category Components
 */
function HomeNavSmall() {
  const classes: any = useDomToolkit()
  const styles = getStyles(classes)

  const links = [
    { path: 'progress', icon: <MdShowChart /> },
    { path: 'engagement', icon: <MdPersonOutline /> },
    { path: 'activity', icon: <MdDataUsage /> }
  ]
  return (
    <header className='HomeNavSmall'>
      <nav className={classes.domFlex}>
        {/* Nav */}
        <div className={`${styles.HomeNav} step1`}>
          {links.map((link, i) => (
            <HomeNavLink to={`/${link.path !== 'progress' ? link.path : ''}`} key={i} link={link}>
              <>
                {link.icon}
                <span>{I18n.t(`screen.${link.path}`)}</span>
              </>
            </HomeNavLink>
          ))}
        </div>
      </nav>
    </header>
  )
}

export default HomeNavSmall
