export const getTimeFromAwsTimeFormat = (time: string) => {
  const year = time.slice(0, 4)
  const month = time.slice(4, 6)
  const day = time.slice(6, 8)
  const hour = time.slice(9, 11)
  const min = time.slice(11, 13)
  const sec = time.slice(13, 15)
  const UTCtime = year + '-' + month + '-' + day + 'T' + hour + ':' + min + ':' + sec + 'Z'
  return UTCtime
}

export const getHeadersFromUrl = (url: string) => {
  const response = {}
  url
    .split('?')[1]
    .split('&')
    .forEach(el => {
      const key = el.split('=')[0]
      const value = el.split('=')[1]
      response[key] = value
    })
  return response
}
