/**
 * @file Manages the Init app methods.
 */

import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'

import { domUtils, domToolkit, DomoscioSdkLocales } from '@domoscio/domoscio-sdk-js'
import { Client, Session, Theme, User } from '../contexts/parameters'
import { domoscioAccess } from '../api/domoscio_access'
import { useInitialization } from './useInitialization'
import I18n from '../locales/I18n'
import AppLoader from '../components/app/AppLoader'
import { Loader, DomUILocales } from '@domoscio/domoscio-ui'
import {
  ObjectivesFilterContext,
  ExperiencesFilterContext,
  EntityFilterContext,
  SkillsContext
} from '../contexts/parameters/filterContext'
import initFilters from './initFilters'
import * as Errors from '../components/Errors'
import * as Sentry from '@sentry/react'

/**
 * Initialize app client & user
 *
 * @category Components
 */
function Initializers({ children }: any) {
  // Contexts
  const client = React.useContext(Client.State)
  const clientDispatch = React.useContext(Client.Dispatch)
  // const theme = React.useContext(Theme.State)
  const themeDispatch = React.useContext(Theme.Dispatch)
  const userDispatch = React.useContext(User.Dispatch)
  const sessionDispatch = React.useContext(Session.Dispatch)
  const { setObjectiveFilters } = React.useContext(ObjectivesFilterContext)
  const { setExperienceFilters, setSelectedExperience } = React.useContext(ExperiencesFilterContext)
  const { setEntities, setSelectedEntity } = React.useContext(EntityFilterContext)
  const { setSkills } = React.useContext(SkillsContext)
  // Hooks
  const [user, setUser] = React.useState<any>({})
  const navigate = useNavigate()
  const location = useLocation()
  const handleError = useErrorHandler()

  const useQuery = () => new URLSearchParams(location.search)
  const setTheme = (theme: any) =>
    themeDispatch(theme) && localStorage.setItem('theme', JSON.stringify(theme))

  const debugTrace = (trace: any) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(trace)
    }
  }

  /**
   * Append custom theme <style> tag to document.head
   */
  const appendTheme = (theme: any) => {
    const style = document.createElement('style')
    document.head.appendChild(style)
    style.innerHTML = domToolkit.themeToCss(theme)
  }

  /**
   * Apply parameters to LXP
   */
  const applyParameters = (parameters: any, isDownloadMode: boolean) => {
    if (parameters) {
      Sentry.setUser({
        id: parameters?.student_parameters?.uid,
        debugData: sessionStorage?.getItem('accesstoken')?.slice(0, 10)
      })

      const isRiseUpUser = parameters?.client_parameters.riseup_client
      const formatedUserParams = {
        ...parameters?.supervisor_parameters,
        ...parameters?.supervisor_parameters?.account,
        ...parameters?.supervisor_parameters?.user_parameter,
        isRiseUpUser
      }
      const ruOptions = parameters?.session_parameters?.options?.analytics
      const userLanguage = isRiseUpUser ? ruOptions?.lang : formatedUserParams?.lang
      const primaryColor = isRiseUpUser
        ? ruOptions?.primary_color
        : parameters?.client_parameters?.mqb_primary_color

      I18n.locale = userLanguage || 'fr'
      DomUILocales.locale = userLanguage || 'fr'
      DomoscioSdkLocales.locale = userLanguage || 'fr'
      const theme = domUtils.parametersToTheme({
        ...parameters?.client_parameters,
        mqb_primary_color: primaryColor
      })
      setTheme(theme)
      appendTheme(theme)
      userDispatch(formatedUserParams)
      setUser(formatedUserParams)
      sessionDispatch({ ...parameters?.session_parameters, isExportDisabled: {} })
      clientDispatch({ ...parameters?.client_parameters, mqb_primary_color: primaryColor })

      // Redirect to onboarding if user email doesn't exist
      if (isDownloadMode) {
        navigate('/download')
      } else {
        navigate('/')
      }
    }
  }

  /**
   * Get access_token param
   */
  const getAccessToken = () => {
    const query = useQuery()
    const tokenParam = query.get('access_token')

    let accessToken = sessionStorage.getItem('accesstoken')
    if (accessToken === null && tokenParam === null) {
      // No AccessToken in storage or navigation params
      const homePageUrl = sessionStorage.getItem('homePageUrl')
      if (homePageUrl) {
        window.location.href = homePageUrl
      } else {
        Errors.redirect('401')
      }
      debugTrace('ERROR: No AccessToken found')
    }
    if (tokenParam !== null) {
      // Store AccessToken in browser
      accessToken = tokenParam
      sessionStorage.setItem('accesstoken', accessToken)
      Object.defineProperty(domoscioAccess, '_accessToken', {
        value: accessToken
      })
    }
    return accessToken
  }

  /**
   * Try to set parameters values by access token
   */
  const initialize = () => {
    // Check if client context state is empty, if true -> start Init
    if (Object.keys(client).length === 0) {
      const accessToken = getAccessToken()

      if (accessToken !== null) {
        // Start setup
        let jwt = sessionStorage.getItem('jwt') || ''
        // Call Access
        domoscioAccess.getParameters().then(parameters => {
          if (parameters?.statusCode === 401) {
            debugTrace(parameters?.body?.message)
          } else if ('session_parameters' in parameters && 'client_parameters' in parameters) {
            // export screen is diplayed when user click on the link in the email to export all datas of analytics
            const isDownloadMode = parameters?.session_parameters?.origin === 'export'

            applyParameters(parameters, isDownloadMode)
            parameters.supervisor_parameters = {
              ...parameters.supervisor_parameters,
              learning_programs: parameters.learning_programs,
              student_groups: parameters.student_groups
            }
            // for riseup clients fetch only kwowledge nodes
            if (parameters?.client_parameters.riseup_client) {
              domoscioAccess.fetchKnowledgeNode().then(response => setSkills(response))
              //  fetch all filter values
            } else if (!isDownloadMode) {
              initFilters(
                setEntities,
                setObjectiveFilters,
                setExperienceFilters,
                setSelectedEntity,
                setSelectedExperience,
                setSkills,
                handleError,
                parameters.supervisor_parameters || {}
              )
            }

            jwt = domUtils.encodeJWT(parameters, accessToken)
            sessionStorage.setItem('jwt', jwt)
          } else {
            Errors.redirect('500')
            debugTrace('ERROR: Invalid JWT token')
          }
        })
      }
    } else {
      debugTrace('ALERT: Client already init')
    }
  }

  /**
   * On mount || When user is set
   */
  React.useEffect((): any => {
    if (user) {
      Object.keys(user).length === 0 && initialize()
    }
  }, [user])

  const init = useInitialization(user)

  const ready = Object.keys(init?.parameters || {}).length !== 0
  const loadingProcesses = [{ name: 'user', isReady: ready }]

  // As long as not all screens are ready, display loader
  return (
    <AppLoader
      mandatoryProcesses={loadingProcesses}
      loadingComponent={
        // Margin is set specificly for analytics application (see HomeScreen.Loader)
        <div style={{ marginTop: 10 }}>
          <Loader />
        </div>
      }
      minimumLoadingTime={1000}
    >
      <div style={{ height: '100%' }} className={`${user.isRiseUpUser ? 'riseUpMode' : ''}`}>
        {children}
      </div>
    </AppLoader>
  )
}

export default Initializers
