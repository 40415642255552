import React from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer'
import { TargetIcon, styles, StarSvg, getBarLabels } from './subComponents'
type Props = {
  skillData: Array<{ isSucceed: boolean; skillName: string; multiProgressData: any }>
  isOts: boolean
  title: string
}

const ListingSkillsPDFRenderer = ({ skillData, isOts, title }: Props) => {
  const getInterval = (scale: number) => 100 / scale

  const getScalePoz = (index: number, length: number) => {
    return index === 0
      ? styles.scaleNumberFirst
      : index === length - 1
        ? styles.scaleNumberLast
        : styles.scaleNumberMiddle
  }

  return (
    <Document>
      <Page style={styles.page} size='A4'>
        <View>
          <Text style={styles.objectiveName}>{title}</Text>
        </View>
        <View style={styles.labels}>
          {getBarLabels(isOts).map((label, i) => (
            <View style={[styles.labelWrapper, { marginRight: i === 3 ? 0 : 18 }]} key={i}>
              <View style={[styles.labelLeftCol, { marginBottom: i === 2 ? -5 : i === 3 ? 2 : 0 }]}>
                {i === 2 ? (
                  <TargetIcon targetValue={0} scale={0} />
                ) : i === 3 ? (
                  <StarSvg color='#32D296' styles={{ width: 8, height: 8 }} />
                ) : (
                  <View
                    style={[
                      styles.labelBox,
                      { backgroundColor: label.icon === undefined ? label.color : '' }
                    ]}
                  ></View>
                )}
              </View>

              <Text style={{ fontSize: 9, marginLeft: 5 }}>{label.label}</Text>
            </View>
          ))}
        </View>
        <View>
          <View style={styles.skillList}>
            {skillData.map((elem, index) => {
              return (
                <View wrap={false} key={index} style={styles.card}>
                  <View style={styles.skillNameWrapper}>
                    <View style={styles.skillNameLeft}>
                      <Text style={styles.skillName}>{elem.skillName}</Text>
                    </View>
                    <View style={styles.skillNameRight}>
                      {elem.isSucceed ? (
                        <StarSvg color='#32D296' styles={{ width: 12, height: 12 }} />
                      ) : (
                        <View></View>
                      )}
                    </View>
                  </View>
                  <View>
                    <View style={styles.cursorLine}>
                      <View
                        style={
                          (styles.cursor,
                          {
                            marginLeft:
                              (
                                elem.multiProgressData.targetValue *
                                getInterval(elem.multiProgressData.scale)
                              ).toString() + '%'
                          })
                        }
                      >
                        <TargetIcon
                          targetValue={elem.multiProgressData.targetValue}
                          scale={elem.multiProgressData.scale}
                        />
                      </View>
                    </View>
                    <View style={styles.barWrapper}>
                      {elem.multiProgressData.values?.map((bar: any, i: number) => (
                        <View
                          key={i}
                          style={[
                            styles.progressBar,

                            {
                              zIndex: i,
                              backgroundColor: bar?.data?.color,
                              width:
                                (
                                  bar?.data?.value * getInterval(elem.multiProgressData.scale)
                                ).toString() + '%',
                              ...bar.data.styles
                            }
                          ]}
                        ></View>
                      ))}
                    </View>
                    <View style={styles.barScales}>
                      {new Array(elem.multiProgressData.scale + 1).fill(0).map((_, i) => {
                        return (
                          <View
                            style={[
                              styles.scaleNumberWrapper,
                              getScalePoz(i, elem.multiProgressData.scale + 1),
                              {
                                left:
                                  (getInterval(elem.multiProgressData.scale) * i).toString() + '%'
                              }
                            ]}
                            key={i}
                          >
                            <View style={styles.scaleLine}></View>
                            <Text style={styles.scaleNumber}>{i}</Text>
                          </View>
                        )
                      })}
                    </View>
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default ListingSkillsPDFRenderer
