// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ObjectivesFilterContext } from '../contexts/parameters/filterContext'

/*
 * Return context selected objectives uids
 */
export function useObjectiveUids() {
  let uids = []
  const objectives = useContext(ObjectivesFilterContext)
  const location = useLocation()
  const filterEnabled = ['/activity'].includes(location.pathname)

  if ((objectives?.selectedObjective || []).length > 0) {
    // Return all objective.uids of selected experience
    if (objectives.selectedObjective[0].uid === 'all' || filterEnabled) {
      uids = objectives.objectiveFilters.objectivesOfSelectedExp.map(obj => obj.uid)
    } else {
      // Return only selected objective.uid
      uids = [objectives.selectedObjective[0].uid]
    }
  }

  return uids
}
