import React from 'react'

type DataIsReady = {
  isDataReady: boolean
  setIsDataReady: (arg) => void
}

const defaultContext = {
  isDataReady: false,
  setIsDataReady: () => {}
}
const DataIsReadyContext = React.createContext<DataIsReady>(defaultContext)

type Props = {
  children: any
}
const DataIsReadyForTourWrapper = ({ children }: Props) => {
  const [isDataReady, setIsDataReady] = React.useState(false)

  const value = { isDataReady, setIsDataReady }
  return <DataIsReadyContext.Provider value={value}>{children}</DataIsReadyContext.Provider>
}

export { DataIsReadyForTourWrapper, DataIsReadyContext }
