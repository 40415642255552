import React, { useLayoutEffect, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { AdaptiveContent as DisplayContent } from '@domoscio/domoscio-ui'
import { useStudentDatas } from '../../StudentScreenHub'

const AdaptiveContent = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const objectiveId = params.id
  const contenData = location?.state?.content as any
  const { isOts, primaryColor } = useStudentDatas()

  useLayoutEffect(() => {
    if (!location?.state?.content) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='adaptiveContentScreen'>
      <div
        className='tsrClose'
        onClick={() => navigate(`/student/objective/${objectiveId}/adaptive`)}
      >
        <MdClose size={30} color='#777' />
      </div>
      <DisplayContent data={contenData} isOts={isOts} primaryColor={primaryColor} />
    </div>
  )
}

export default AdaptiveContent
