import { useQuery } from 'react-query'
import { domoscioAccess } from '../api/domoscio_access'

export const useFetchKnLevels = (learningSessionId: string | number | undefined, isEnabled) => {
  const getKnLevels = learningSessionId => {
    return useQuery(
      ['KnLevels', learningSessionId],
      async () => {
        return domoscioAccess.getLearningSessionKnowledgeNodes({
          learning_session_id: learningSessionId
        })
      },
      {
        enabled: typeof learningSessionId === 'number' && isEnabled
      }
    )
  }

  const { data: skillLevels, status } = getKnLevels(learningSessionId)

  return { skillLevels, isLoading: status === 'loading', error: status === 'error' }
}
