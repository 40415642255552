import { useState, useEffect } from 'react'
// Define general type for useWindowSize hook, which includes width and height
interface Size {
  width: number | undefined
}

export const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window?.visualViewport?.width
      })
    }
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}
