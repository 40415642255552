import React, { useContext } from 'react'
import '../lock.scss'
import { AbsoluteNumber, Percentage, MultiPercentage, StatLoader } from '@domoscio/domoscio-ui'
import TitleScreen from '../../../components/TitleScreen'
import Card from '../../../components/cards/Card'
import Table from '../../../components/DataTable/Table'
import I18n from '../../../locales/I18n'
import { MdOutlinePsychology } from 'react-icons/md'
import { Client, Theme } from '../../../contexts/parameters'
import { FilterBodyContext } from '../../../contexts/parameters/filterBodyContext'
import { ObjectivesFilterContext, SkillsContext } from '../../../contexts/parameters/filterContext'
import { getLegendOfLevel, knowledgeNodesLevel, useCiblingDatas } from '../utils'
import { absoluteNumbersElements, percentageElements, pieChartElements } from './datas'
import { useFetchAnalyticsDatas } from '../../../hooks/useFetchAnalyticsDatas'
import { useRiseUpClient } from '../../../hooks/useRiseUpClient'
import ProgressData from '../../../components/Progress/ProgressData'
import './ProgressionScreenLock.scss'
function ProgressScreenLock() {
  // Contexts
  const client = useContext(Client.State) as any
  const body = useContext(FilterBodyContext)
  const { selectedObjective, objectiveFilters } = useContext(ObjectivesFilterContext)
  const { skills } = useContext(SkillsContext)
  const theme = useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary
  const { isRiseup } = useRiseUpClient()

  const isOnlyQuestion = isRiseup
    ? true
    : objectiveFilters.objectivesOfSelectedExp.every(
        obj => obj.memorize_options.workflow === 'only_questions'
      )

  // Catch data results
  const { data, status } = useFetchAnalyticsDatas({
    tab: 'LockProgression'
  })

  const bodyWithContext = { ...body, objective: selectedObjective }
  const { datas } = isRiseup
    ? { datas: data?.values }
    : useCiblingDatas(data?.body, bodyWithContext)
  const riseUpData = isRiseup ? datas : undefined

  const knowledgeNodesLevels =
    selectedObjective.length > 0 || riseUpData
      ? knowledgeNodesLevel(data?.body, skills, selectedObjective[0], riseUpData)
      : []
  const displayLevels =
    !(
      selectedObjective.length > 0 &&
      (selectedObjective[0].id === 'all' || selectedObjective[0].id === 'no')
    ) || isRiseup

  return client && status === 'success' ? (
    <div className='ProgressScreenLock'>
      <div className='mainProgressionHub'>
        <div className='step3' style={{ marginBottom: isOnlyQuestion ? 50 : 0 }}>
          {/* Progress */}
          <TitleScreen
            titleScreen={I18n.t('progress.main_title')}
            data-testid='titleScreenEngagementHub'
          />
          <div className='graphes'>
            <div className='firstLine'>
              <div className='col smallStats'>
                <div className='componentAbsoluteNumber'>
                  <AbsoluteNumber
                    {...absoluteNumbersElements(datas, 'learners', !displayLevels, isRiseup)}
                  />
                </div>
                <div className='componentAbsoluteNumber'>
                  <AbsoluteNumber
                    {...absoluteNumbersElements(datas, 'knowledge_nodes', !displayLevels, isRiseup)}
                  />
                </div>
                {!isRiseup ? (
                  <div className='percentage'>
                    <Percentage {...percentageElements(datas, isRiseup)} />
                  </div>
                ) : (
                  <div className='componentAbsoluteNumber'>
                    <AbsoluteNumber
                      {...absoluteNumbersElements(
                        datas,
                        'knowledge_nodes_disabled',
                        !displayLevels,
                        isRiseup
                      )}
                    />
                  </div>
                )}
              </div>
              {isRiseup && (
                <div className='percentage line1_col2'>
                  <Percentage {...percentageElements(datas, isRiseup)} />
                </div>
              )}
              <div className='col carret'>
                <Card {...pieChartElements(datas, 'questions', primaryColor, isRiseup)} />
              </div>
              {/* Conditionnal display */}
              {displayLevels && knowledgeNodesLevels.length > 0 && !isRiseup && (
                <div className='col carret'>
                  <MultiPercentage
                    key={selectedObjective[0].uid} // 👈 force rerender if selected themen n the filter chenged
                    datas={knowledgeNodesLevels}
                    legend={getLegendOfLevel}
                    generalTooltipText={I18n.t('progress.tooltips.average_memorization_notion')}
                    highlightSelection
                    icon={<MdOutlinePsychology />}
                    title={I18n.t('progress.memorization_level_by_notion')}
                  />
                </div>
              )}
            </div>

            <div className='newProgressLine'>
              {isRiseup && displayLevels && knowledgeNodesLevels.length > 0 && (
                <ProgressData progressData={knowledgeNodesLevels} />
              )}
            </div>

            {/* Challenges */}
            {!isOnlyQuestion && (
              <>
                <TitleScreen titleScreen={I18n.t('global.challenges')} />
                <div className='graphes graphesChallenges'>
                  <div className='secondLine challenges'>
                    <div className='componentAbsoluteNumber challengeCount'>
                      <AbsoluteNumber {...absoluteNumbersElements(datas, 'challenges')} />
                    </div>
                    <div className='percentage challengePie'>
                      <Card {...pieChartElements(datas, 'challenges', primaryColor)} />
                    </div>
                    <div className='percentage challengePie'>
                      <Card {...pieChartElements(datas, 'challenges2', primaryColor)} />
                    </div>
                  </div>
                </div>{' '}
              </>
            )}
          </div>
        </div>
        <Table page={'LockProgression'} />
      </div>
    </div>
  ) : (
    <StatLoader primaryColor={primaryColor} />
  )
}

export default ProgressScreenLock
