import React, { useState } from 'react'
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import UnderlinedButton from '../UnderlinedButton/UnderlinedButton'
import I18n from '../../../../../locales/I18n'
import { useStudentDatas } from '../../StudentScreenHub'
import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'
type Screen = 'path' | 'skills'

const AdaptiveNavBar = () => {
  const [activeScreen, setActiveScreen] = useState<Screen>('path')
  const buttons = ['path', 'skills']
  const navigate = useNavigate()
  const params = useParams()

  const { isOts, primaryColor, objectivesStudent } = useStudentDatas()
  const selectedObjective = objectivesStudent.find(os => os.objective_id === Number(params.id))

  const handlNavigation = (btn: Screen) => {
    setActiveScreen(btn)
    const objId = params?.id
    const url = `/student/objective/${objId}/adaptive`
    if (btn === 'path') {
      navigate(url)
    } else {
      navigate(url + '/skills')
    }
  }
  return (
    <div className='adaptiveNavBar'>
      <div>
        <div className='navigationButtons'>
          {buttons.map((btn, index) => {
            return (
              <UnderlinedButton
                key={index}
                isActive={btn === activeScreen}
                handleClick={() => handlNavigation(btn as Screen)}
                isPrimary={!isOts}
              >
                {I18n.t(`studentScreen.screen.adaptive_${btn}`)}
              </UnderlinedButton>
            )
          })}
        </div>

        <Outlet context={{ isOts, primaryColor, selectedObjective }} />
      </div>
    </div>
  )
}

export default AdaptiveNavBar
type ContextType = {
  primaryColor: string
  selectedObjective: lxpHubTypes.ObjectiveStudent
  isOts: boolean
}

export function useStudentDatasOfObjective() {
  return useOutletContext<ContextType>()
}
