import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import './ErrorFallback.css'
import * as Errors from '../Errors'
import { Theme } from '../../contexts/parameters'
import { useDomToolkit } from '../../styles/dom-toolkit'
import * as Sentry from '@sentry/react'

interface AuxProps {
  children: any
}

function NoContentError({ children, fill }: any) {
  const classes: any = useDomToolkit()
  return (
    <div
      className={`${classes.domFlexPerfect} ${classes.domFlex1} ${classes.domTextCenter}`}
      style={{ height: '100vh', width: '100%' }}
    >
      <Errors.ContentUnavailableErrorScreen fill={fill}>
        <h2>{children}</h2>
      </Errors.ContentUnavailableErrorScreen>
    </div>
  )
}

function ErrorFallback({ error }: any) {
  Sentry.captureException(error)
  const theme = React.useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary
  if (error.name === 'NoContentError') {
    return <NoContentError fill={primaryColor}>{error.message}</NoContentError>
  } else {
    return <Errors.ErrorScreen fill={primaryColor} />
  }
}

const ErrorWrapper = ({ children }: AuxProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
)

export default ErrorWrapper
