/* eslint-disable camelcase */
/* eslint-disable react/display-name */
import React, { useContext, useState } from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'
import { useDomToolkit } from '../../../styles/dom-toolkit'
import { domoscioAccess } from '../../../api/domoscio_access'
import { FilterBodyContext } from '../../../contexts/parameters/filterBodyContext'
import Popup from 'reactjs-popup'
import './ExportButton.scss'
import ExportModal from './ExportModal'
import { Session } from '../../../contexts/parameters'
import { ExperiencesFilterContext } from '../../../contexts/parameters/filterContext'

const ExportButton: React.FC<any> = () => {
  const [openModal, setOpenModal] = useState(false) // condition to display

  const classes: any = useDomToolkit()
  const { selectedExperience } = useContext(ExperiencesFilterContext)
  const experienceUid = selectedExperience[0].uid || ''

  const closeModal = () => setOpenModal(false)

  // Context
  const filters = React.useContext(FilterBodyContext)

  const session = React.useContext(Session.State)
  const sessionDispatch = React.useContext(Session.Dispatch)
  // Button click handler
  const handleExportButtonClick = async () => {
    // kpi logs (feature tracking)
    domoscioAccess.postKpiLog('SupervisorExportDownload')

    // first check if all filters are ready
    const { student_group_uid, learning_program_uid, start_date, end_date } = filters

    const filterValuesIsReady =
      student_group_uid?.length > 0 && learning_program_uid?.length > 0 && start_date && end_date
    const getOnlyDayFromUTC = (time: string) => time.split('T')[0]
    if (filterValuesIsReady) {
      // Construct query body to get export mail
      const exportBody = {
        student_group_uid: student_group_uid,
        learning_program_uid: learning_program_uid[0],
        date_range: [getOnlyDayFromUTC(start_date), getOnlyDayFromUTC(end_date)]
      }

      await domoscioAccess.getGlobalExport(exportBody)
      sessionDispatch({
        isExportDisabled: {
          ...(session.isExportDisabled || {}),
          [experienceUid]: true
        }
      })
      setOpenModal(true)
    }
  }

  let isExportButtonDisabled = false
  let isDisplayExportButton = false

  const exportableExperienceList = session.isExportDisabled

  if (exportableExperienceList) {
    if (exportableExperienceList[experienceUid]) {
      isExportButtonDisabled = true
    }
    if (Object.keys(exportableExperienceList).includes(experienceUid)) {
      isDisplayExportButton = true
    }
  }

  return (
    <div
      style={{ display: isDisplayExportButton ? 'block' : 'none' }}
      className={`exportButtonContainer  ${isExportButtonDisabled ? 'disabledWrapper' : ''}`}
    >
      <button
        disabled={isExportButtonDisabled}
        className={`exportButton 
        ${isExportButtonDisabled ? 'disabledButton' : classes.domBgPrimary + ' activeButton'} 
        
        `}
        onClick={() => handleExportButtonClick()}
      >
        <MdOutlineFileDownload size={20} />
      </button>
      <Popup
        position={'top center'}
        offsetY={100}
        offsetX={200}
        open={openModal}
        closeOnDocumentClick
        onClose={closeModal}
        className='custom'
      >
        <ExportModal closeModal={closeModal} />
      </Popup>
      {isExportButtonDisabled && <ExportModal.Tooltip />}
    </div>
  )
}

export default ExportButton
