import React from 'react'
import I18n from '../../../locales/I18n'
import { MdOutlineGroups, MdSmartphone, MdOutlinePsychology } from 'react-icons/md'
import { PieChart } from '@domoscio/domoscio-ui'
import { formatActivity } from '../../../utils/format'

interface Data {
  [key: string]: any | number | undefined
}

type absoluteNumbersCibling = 'learners' | 'reviews'

/*
 * List of data props of AbsoluteNumber in ProgressionScreenLock
 */
export const absoluteNumbersElements = (
  data: Data | undefined,
  cibling: absoluteNumbersCibling = 'learners',
  isRiseUp?: boolean
) => {
  const container = { containerClassName: 'small_container' }
  if (cibling === 'learners') {
    return {
      ...container,
      icon: <MdOutlineGroups />,
      data: data?.nbr_students || 0,
      title: I18n.t('activity.learners', { count: data?.nbr_students || 0 }),
      generalTooltipText: I18n.t(`engagement.tooltips.learners${isRiseUp ? 'v2' : ''}`)
    }
  } else {
    return {
      ...container,
      icon: <MdOutlinePsychology />,
      data: data?.nbr_reviews_done || 0,
      title: I18n.t(`activity.lock_review_done${isRiseUp ? 'v2' : ''}`, {
        count: data?.nbr_reviews_done || 0
      }),
      generalTooltipText: I18n.t(`activity.tooltips.lock_review_done${isRiseUp ? 'v2' : ''}`)
    }
  }
}

/*
 * List of data props of Percentage in ProgressionScreenLock
 */
export const percentageElements = (data: Data | undefined) => {
  const container = { containerClassName: 'medium_container' }
  return {
    ...container,
    progress: data?.perc_students_mobile_app || 0,
    isZero: data?.perc_students_mobile_app === 0,
    title: I18n.t('activity.learners_mobile'),
    generalTooltipText: I18n.t('activity.tooltips.learners_mobile'),
    icon: <MdSmartphone />
  }
}

/*
 * List of data props of Card.PieChart in ProgressionScreenLock
 */
export const pieChartElements = (data: Data | undefined, primaryColor: string | undefined) => {
  const container = { containerClassName: 'medium_container' }
  const colors = {
    primaryColor: primaryColor || '#00AACE',
    secondaryColor: '#103F4B'
  }
  return {
    ...container,
    generalTooltipText: I18n.t('activity.tooltips.lock_usage_repartition'),
    icon: <MdOutlinePsychology />,
    title: I18n.t('activity.lock_review_done.other'),
    children: React.createElement(
      'div',
      { className: 'pieContainer' },
      React.createElement(PieChart, {
        ...colors,
        pieWidth: 130,
        datas: [
          {
            name: I18n.t('activity.usage_type.mobile'),
            value: data?.perc_reviews_distribution?.mobile_app
          },
          {
            name: I18n.t('activity.usage_type.web'),
            value: data?.perc_reviews_distribution?.web_app
          }
        ]
      })
    )
  }
}

type evolutionGraph = 'learners' | 'reviews'

/*
 * List of data props of EvolutionGraph in ProgressionScreenLock
 */
export const evolutionGraphElements = (
  data: Data | undefined,
  cibling: evolutionGraph = 'learners',
  isRiseUp?: boolean
) => {
  const props = { containerClassName: 'large_container', isAnimationActive: true }
  if (cibling === 'learners') {
    return {
      ...props,
      icon: <MdOutlineGroups />,
      generalTooltipText: I18n.t('activity.tooltips.lock_learners_graph'),
      title: I18n.t('activity.learners.other'),
      data: formatActivity(data?.monthly_students_subscription || []),
      labelTooltip: {
        plural: I18n.t('activity.tooltips.registration.plural'),
        singular: I18n.t('activity.tooltips.registration.singular')
      }
    }
  } else {
    return {
      ...props,
      icon: <MdOutlinePsychology />,
      generalTooltipText: I18n.t(`activity.tooltips.lock_review_done_graph${isRiseUp ? 'v2' : ''}`),
      title: I18n.t(`activity.lock_review_done${isRiseUp ? 'v2' : ''}.other`),
      data: formatActivity(data?.monthly_reviews_done || []),
      labelTooltip: {
        plural: isRiseUp
          ? I18n.t(`activity.lock_review_donev2.other`)
          : I18n.t('activity.tooltips.activity_lock.plural'),
        singular: isRiseUp
          ? I18n.t(`activity.lock_review_donev2.one`)
          : I18n.t('activity.tooltips.activity_lock.singular')
      }
    }
  }
}
