import React from 'react'
import './StatFiltersSmall.css'
import { MdList, MdChevronLeft } from 'react-icons/md'
import { useDomToolkit, Style } from '../../styles/dom-toolkit'
import StatFilters from './StatFilters'
import I18n from '../../locales/I18n'

type Nullable<T> = T | null
interface SliderProps {
  isOpen: boolean
  children: Nullable<React.ReactElement>
}

const SlidePanel: React.FunctionComponent<SliderProps> = ({ isOpen, children }) => {
  return <div className={`SlidePanel ${isOpen ? 'open' : 'close'}`}>{children}</div>
}

const getStyles = (classes: any) =>
  Style.create({
    openLink: [classes.domFlexPerfect],
    openLinkIcon: [classes.domMarginRight, classes.domText30],
    HomeNav: [classes.domFlex, classes.domFlex2, classes.domFlexCenter, classes.domMarginTop]
  })

const StatFiltersSmall = ({ ...props }) => {
  const [openPanel, setOpenPanel] = React.useState(false)

  const classes: any = useDomToolkit()
  const styles = getStyles(classes)

  return (
    <div className='StatFiltersSmall'>
      <button className='openButton' onClick={() => setOpenPanel(true)}>
        <MdList className={`${classes.domMarginRight + ' ' + classes.domText20}`} />
        {I18n.t('global.filtering')}
      </button>

      <SlidePanel isOpen={openPanel}>
        <>
          <div className={styles.openLink} onClick={() => setOpenPanel(false)}>
            <MdChevronLeft className={styles.openLinkIcon} />
            <span className={classes.domText16}>{I18n.t('global.filtering')}</span>
          </div>
          <StatFilters {...props} />
        </>
      </SlidePanel>
    </div>
  )
}

export default StatFiltersSmall
