import React, { useEffect, useState } from 'react'
import I18n from '../../../locales/I18n'
import { FormationProgress, Loader, SkillLabels, SkillProgress } from '@domoscio/domoscio-ui'
import { getAverageMemoryLevel, getObjectiveStudents } from './utils'
import { useRiseUpClient } from '../../../hooks/useRiseUpClient'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

interface Props {
  uid: string
  title: string
}

type Status = 'idle' | 'loading' | 'success' | 'error'

type SkillProgressType = {
  percentage: number
  isActive: boolean
  name: string
}

const StudentScreenRiseUp = ({ uid, title }: Props) => {
  const [data, setData] = useState<any>(null)
  const [status, setStatus] = useState<Status>('idle')
  const navigate = useNavigate()

  const { primaryColor, objectiveId } = useRiseUpClient()

  const getDate = objectiveStudents => {
    const objective = objectiveStudents?.find(el => el.objective_id === Number(objectiveId))

    // Create a new Date object from the input timestamp
    const date = new Date(objective?.created_at)

    // Extract the day, month, and year
    const day = String(date.getUTCDate()).padStart(2, '0')
    const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
    const year = date.getUTCFullYear()

    // Format the date as "dd/mm/yyyy"
    return `${day}/${month}/${year}`
  }

  const formatData = (progressData: any, objectiveStudents) => ({
    date: getDate(objectiveStudents),
    skillCount: progressData.nb_knowledge_nodes,
    isActive: true,
    formationName: progressData.name,
    skills: Object.keys(progressData.knowledge_nodes).map(skillId => {
      const skill = progressData.knowledge_nodes[skillId]
      return {
        percentage: Math.floor(Number(((skill.average_memory_level / 3.0) * 100).toFixed())),
        isActive: skill.active,
        name: skill.name,
        id: skillId
      }
    })
  })

  useEffect(() => {
    setStatus('loading')
    getObjectiveStudents(uid).then(res => {
      getAverageMemoryLevel([Number(objectiveId)], uid).then(memoLevels => {
        setData(formatData(memoLevels.objectives[Number(objectiveId)], res))
        setStatus('success')
      })
    })
  }, [])

  // calculate course progress based on skill progress
  const getFormationProgress = (skillsDatas: SkillProgressType[]) => {
    const count = skillsDatas?.length // skill count

    // course = total progress / skill count
    const courseProgress =
      skillsDatas.reduce((accumulator, currentValue) => accumulator + currentValue.percentage, 0) /
      count
    return Math.floor(courseProgress)
  }

  // memo levels
  const labals = ['days', 'week', 'weeks', 'months'].map(label =>
    I18n.t(`progress.review_levels.${label}`)
  )

  if (status === 'loading' || status === 'idle') {
    return <Loader />
  }

  return (
    <div className='studentScreenRiseUp'>
      <div className='studentScreenHeader'>
        <button onClick={() => navigate(-1)} style={{ color: primaryColor }} className='backButton'>
          <span>
            <MdKeyboardArrowLeft />
          </span>
          <span>{I18n.t('tour.locales.back')}</span>
        </button>
        <div className='studentName'>{title}</div>
      </div>
      <div className='progressDataStudent'>
        <div className={`formationWrapper ${data.isActive ? 'default' : 'disabledCourse'}`}>
          <div className='firstLine2'>
            <FormationProgress
              skillCount={data.skillCount}
              name={data.formationName}
              date={data.date}
              percentage={Number(getFormationProgress(data.skills))}
              primaryColor={data.isActive ? primaryColor : '#898AA0'}
            />
          </div>
          <div className='skillsSection'>
            <SkillLabels labels={labals}>
              {data.skills.map((el, i) => (
                <div key={i}>
                  <SkillProgress
                    {...el}
                    helpText={I18n.t('home.memo_inactive_tooltip')}
                    primaryColor={primaryColor}
                    isLastElem={i === data.skills.length - 1}
                  />
                </div>
              ))}
            </SkillLabels>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentScreenRiseUp
