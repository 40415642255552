import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

export const exportToExcel = async (excelData: any, headers: any, fileName: string) => {
  excelData = excelData.map((data: any) => {
    const newData = {} as any
    headers.forEach((header: any) => {
      newData[header.label] = data[header.key] || '-'
    })
    return newData
  })

  const ws = XLSX.utils.json_to_sheet(excelData)
  const excelBuffer = XLSX.write(
    {
      Sheets: { data: ws },
      SheetNames: ['data']
    },
    { bookType: 'xlsx', type: 'array' }
  )
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}
