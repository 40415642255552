import React from 'react'
import {
  AbsoluteNumber,
  Percentage,
  BarComparasion,
  PercentageComparison
} from '@domoscio/domoscio-ui'
import {
  MdOutlineGroups,
  MdAlarm,
  MdForum,
  MdOutlineVerified,
  MdOutlineQuiz,
  MdOutlineLibraryAddCheck
} from 'react-icons/md'
import I18n from '../../../../locales/I18n'

import { getTimePerPositionning, getPercComparisonData, getBarComparisonData } from '../utils'
import { ObjectivesFilters, Option } from '../../../../types/filter'
import { TimeOnResources } from './TimeOnResources'

type Props = {
  dataOfSelectedTheme: any
  objectives: ObjectivesFilters
  selectedObjective: Option[]
}
const ProgressPositioning = ({ dataOfSelectedTheme, objectives, selectedObjective }: Props) => {
  const isOnlyOnePositioning =
    objectives.objectivesOfSelectedExp.filter(elem => elem.id === selectedObjective[0].id)[0]
      .attempt_options?.workflow === null

  const totalRessourceTime = dataOfSelectedTheme?.avg_recommendation_time

  return (
    <div className='positionningGraphs'>
      {/* 👇 condition to check if there is one or more positionings */}
      {!isOnlyOnePositioning ? (
        <section className='case1'>
          <div className='graphWrapper'>
            <PercentageComparison
              title={I18n.t('progress.correct_answers') + I18n.t('progress.per_quiz')}
              generalTooltipText={I18n.t('progress.tooltips.hub_correct_answers')}
              innerCircle={getPercComparisonData(dataOfSelectedTheme, 'correctAnswers').innerCircle}
              outerCircle={getPercComparisonData(dataOfSelectedTheme, 'correctAnswers').outerCircle}
              icon={<MdOutlineLibraryAddCheck />}
            />
          </div>
          <div className='graphWrapper'>
            <PercentageComparison
              title={I18n.t('progress.validated_skills') + I18n.t('progress.per_quiz')}
              generalTooltipText={I18n.t('progress.tooltips.validated_skills')}
              innerCircle={getPercComparisonData(dataOfSelectedTheme).innerCircle}
              outerCircle={getPercComparisonData(dataOfSelectedTheme).outerCircle}
              icon={<MdOutlineVerified />}
            />
          </div>
          <div className='graphWrapper'>
            <BarComparasion
              title={I18n.t('progress.hub_questions_answered.other') + I18n.t('progress.per_quiz')}
              generalTooltipText={I18n.t('progress.tooltips.hub_questions_answered')}
              data={getBarComparisonData(dataOfSelectedTheme, 'answeredQuestions')}
              icon={<MdOutlineQuiz />}
              type='number'
            />
          </div>
          <div style={{ display: totalRessourceTime ? 'flex' : 'inline' }}>
            <div className='graphWrapper'>
              <BarComparasion
                title={I18n.t('progress.time_spent') + I18n.t('progress.per_quiz')}
                generalTooltipText={I18n.t('progress.tooltips.hub_time_spent')}
                data={getBarComparisonData(dataOfSelectedTheme)}
                icon={<MdAlarm />}
                type='time'
              />
            </div>
            {totalRessourceTime ? (
              <TimeOnResources inlineStyle={{ marginLeft: 20 }} time={totalRessourceTime} />
            ) : (
              <></>
            )}
          </div>
        </section>
      ) : (
        <section className={`case2 ${totalRessourceTime ? 'totalRessourceTimeStyle' : ''}`}>
          <div className='col1'>
            <div className='componentAbsoluteNumber'>
              <AbsoluteNumber
                icon={<MdAlarm />}
                containerClassName='small_container'
                title={I18n.t('progress.per_positioning')}
                generalTooltipText={I18n.t('progress.tooltips.per_positioning')}
                data={getTimePerPositionning(dataOfSelectedTheme?.avg_first_positioning_time)}
              />
            </div>
            <div className='componentAbsoluteNumber'>
              <AbsoluteNumber
                icon={<MdOutlineGroups />}
                containerClassName='small_container'
                data={dataOfSelectedTheme?.avg_first_positioning_nbr_questions?.toFixed() || 0}
                title={I18n.t('progress.hub_questions_answered', {
                  count: dataOfSelectedTheme?.avg_first_positioning_nbr_questions || 0
                })}
                generalTooltipText={I18n.t('progress.tooltips.hub_questions_answered')}
              />
            </div>
          </div>
          <div className='col2'>
            {totalRessourceTime ? <TimeOnResources time={totalRessourceTime} /> : <></>}
            <div className='percentage'>
              <Percentage
                progress={dataOfSelectedTheme?.avg_first_positioning_perc_correct_answers}
                containerClassName='medium_container'
                title={I18n.t('progress.correct_answers')}
                generalTooltipText={I18n.t('progress.tooltips.hub_correct_answers')}
                icon={<MdForum />}
                isZero={dataOfSelectedTheme?.avg_first_positioning_perc_correct_answers === 0}
              />
            </div>
          </div>
        </section>
      )}
    </div>
  )
}

export default ProgressPositioning
