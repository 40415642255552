import React, { ReactComponentElement, FunctionComponentElement } from 'react'
import { CardContainer, InformationIcon, HeaderStat } from '@domoscio/domoscio-ui'

export type CardProps = {
  children: React.ReactNode | FunctionComponentElement<any> | string
  containerClassName?: string
  title: string
  generalTooltipText: string
  icon: ReactComponentElement<any>
}

const Card = ({
  children,
  containerClassName = 'containerPercentageComp',
  title,
  generalTooltipText,
  icon
}: CardProps) => {
  return (
    <CardContainer containerClassName={containerClassName}>
      <InformationIcon generalTooltipText={generalTooltipText} isDataTable={false} />
      <HeaderStat icon={icon} title={title} color={'#8F9BBA'} />
      <div>{children}</div>
    </CardContainer>
  )
}

export default Card
