import React, { useState } from 'react'
import { StatusModal } from '@domoscio/domoscio-ui'
import Popup from 'reactjs-popup'
import I18n from '../../locales/I18n'
import { Client, User, Session } from '../../contexts/parameters'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import './FeaturePopup.scss'
import { DataIsReadyContext } from '../../contexts/dataIsReadyContext'
const FeaturePopup = () => {
  const [isOpenModal, setOpenModal] = useState(true)

  const featurePopupEndDate = '04-01-2024' // Display this pop up until April 01 2024
  const isPopupExpired = new Date(featurePopupEndDate) < new Date()

  //  hub or lock
  const client = React.useContext(Client.State)
  const mode = client?.product || 'hub'
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const IsTourSeeen = user?.onboarding?.analytics?.home

  const isPopupAlreadySeen = user?.onboarding?.analytics?.exportPopup

  const { isDataReady } = React.useContext(DataIsReadyContext)

  const session = React.useContext(Session.State)
  const isFirstConnection = session?.isFirstConnection

  const isDisplayPopup =
    isOpenModal &&
    !isPopupExpired &&
    !isPopupAlreadySeen &&
    IsTourSeeen &&
    isDataReady &&
    !isFirstConnection

  const closeModal = () => {
    setOpenModal(false)
    if (isDisplayPopup) {
      // update use onboarding, show this popup only one time to user
      userDispatch({
        onboarding: domUtils.updateTour(user.onboarding, 'analytics', { exportPopup: true })
      })
    }
  }
  return (
    <Popup
      position={'top center'}
      offsetY={100}
      offsetX={200}
      open={isDisplayPopup}
      closeOnDocumentClick
      onClose={closeModal}
      className='infoPopup'
    >
      <StatusModal
        title={I18n.t('export.info_popup.title')}
        body={I18n.t(`export.info_popup.body_${mode}`)}
        onClose={closeModal}
      />
    </Popup>
  )
}

export default FeaturePopup
