import React from 'react'
import { useStudentDatasOfObjective } from '../../subComponents/AdaptiveNavBar/AdaptiveNavBar'
import { lxpHubUtils } from '@domoscio/domoscio-sdk-js'
import { ActionCardV2, ActionElementQuiz, LearningPathV2, Loader } from '@domoscio/domoscio-ui'
import { Client } from '../../../../../contexts/parameters/client'
import { useQuery } from 'react-query'
import { domoscioAccess } from '../../../../../api/domoscio_access'
import { useNavigate } from 'react-router-dom'
import { exportLearningPath } from './utils'

const AdaptivePath = () => {
  const { selectedObjective, isOts } = useStudentDatasOfObjective()

  const lsA = selectedObjective?.learning_sessions.find(ls => ls.type === 'LearningSessionAdaptive')
  const client = React.useContext(Client.State)

  const quizDoneList = lxpHubUtils.getQuizDoneList(selectedObjective.learning_sessions)
  const isFirstQuizDone = quizDoneList.length > 0

  const isEvalExtern =
    selectedObjective?.objective?.adaptive_options?.positioning_type === 'Assessment'

  const navigate = useNavigate()
  // for some clients learner can not validate resources (don't send a completed event)
  const isResourceCanBeValidate = client.mqb_update_mastery_on_read

  const getLearningPath = learningSessionId => {
    return useQuery(
      ['LearningPath', learningSessionId],
      async () => {
        return domoscioAccess.getLearningPath({ learning_session_id: learningSessionId })
      },
      {
        enabled: isFirstQuizDone
      }
    )
  }

  const { data: learningPath, status } = getLearningPath(lsA?.id)

  const learningPathData = isResourceCanBeValidate
    ? lxpHubUtils.prepareLearningPathData(selectedObjective, true, learningPath, true)
    : lxpHubUtils.prepareSkillBasedLearningPath(learningPath, selectedObjective, true)
  if (status === 'loading') {
    return <Loader />
  } else if (status === 'error') {
    return <h2> Seometing went wrong try again</h2>
  }

  const handleExport = async () => {
    const fileName = selectedObjective?.name
    return exportLearningPath(fileName)
  }

  const openLearningContent = (content: any) => {
    const contentId = content.id
    const path = `/student/objective/${selectedObjective.objective_id}/content/${contentId}`

    navigate(path, { state: { content } })
  }

  // <ActionCard.onClick />
  const handleClickCard = (index, contentList: any[], type, status, url) => {
    if (type === 'quiz') {
      if (status === 'done') {
        if (isEvalExtern) {
          return null
        } else {
          // url  is like /learning_sessions/56789 / positionning / result
          const lspId = url.split('/')[2]
          const path = `/student/objective/${selectedObjective.objective_id}/positioning/${lspId}`
          navigate(path, { state: { selectedObjective, isOts } })
        }
      } else {
        return null
      }
    } else if (type === 'source') {
      const data = contentList[index]
      openLearningContent(data)
    } else {
      return null
    }
  }

  const isOnlyPozCard = learningPathData.length === 1 && learningPathData[0].cardType === 'quiz'
  const isOnlyFirstPosCard = isOnlyPozCard && learningPathData[0].status === 'toDo'
  const isDesactivateCursor = isOnlyPozCard && learningPathData[0].status === 'done' && isEvalExtern

  return (
    <div
      className={`adaptivePathScreen ${isOts ? 'otsLearningPath' : ''} ${
        isOnlyFirstPosCard || isDesactivateCursor ? 'onlyPosCard' : ''
      }`}
    >
      <LearningPathV2 onClickExport={handleExport}>
        {learningPathData.map((args, index) => {
          return (
            <ActionCardV2
              isOts={isOts}
              key={index}
              {...args}
              onClick={url =>
                handleClickCard(index, learningPathData, args.cardType, args.status, url)
              }
              actionElement={
                args.cardType === 'quiz' && args.status === 'done' && !isEvalExtern ? (
                  <ActionElementQuiz isStatView={true} />
                ) : (
                  <></>
                )
              }
              status={args.status}
              animate={false}
            />
          )
        })}
      </LearningPathV2>
    </div>
  )
}

export default AdaptivePath
