import React from 'react'
import { useStudentDatasOfObjective } from '../../subComponents/AdaptiveNavBar/AdaptiveNavBar'
import MySkills from '../../subComponents/MySkills/MySkills'

const AdaptiveSkills = () => {
  const { selectedObjective, isOts } = useStudentDatasOfObjective()

  const lsA = selectedObjective.learning_sessions.filter(
    ls => ls.type === 'LearningSessionAdaptive'
  )[0].id

  return (
    <div className='adaptiveSkills'>
      <MySkills isOts={isOts} learningSessionId={lsA} evaluatedObjective={selectedObjective} />
    </div>
  )
}
export default AdaptiveSkills
