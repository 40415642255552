/**
 * @file Manages the NavigationContainer declaration (app routing) @see https://reactrouter.com/
 */

import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Client, Theme } from '../contexts/parameters'

import HomeScreen from '../screens/HomeScreen'
import * as Hub from '../screens/hub'
import * as Lock from '../screens/lock'

import * as Errors from '../components/Errors'
import LearningProgramShow from '../screens/hub/StudentScreen/subScreens/LearningProgramShow/LearningProgramShow'
import AdaptivePath from '../screens/hub/StudentScreen/subScreens/AdaptivePath/AdaptivePath'
import AdaptiveSkills from '../screens/hub/StudentScreen/subScreens/AdaptiveSkills/AdaptiveSkills'
import AdaptiveNavBar from '../screens/hub/StudentScreen/subComponents/AdaptiveNavBar/AdaptiveNavBar'
import AdaptiveContent from '../screens/hub/StudentScreen/subScreens/AdaptiveContent/AdaptiveContent'
import PositioningResult from '../screens/hub/StudentScreen/subScreens/PositionningResult/PositioningResult'
import ExportDownload from '../screens/Shared/ExportDownload/ExportDownload'

/**
 * Navigation <Routes> declaration component
 *
 * @category Components
 */
function Navigation() {
  // ErrorsScreens color
  const theme = React.useContext(Theme.State)
  const fill = '#' + theme?.domBgPrimary.match(/[0-9A-Fa-f]{6}/g)

  // Platform mode conditional routing
  const client = React.useContext(Client.State)
  const mode = client?.product || 'hub'
  return (
    <Routes>
      {/* Main supervisor tabs routes */}
      <Route path='/' element={<HomeScreen />}>
        {mode === 'hub' ? (
          <>
            <Route index element={<Hub.ProgressScreen />} />
            <Route path='engagement' element={<Hub.EngagementScreen />} />
            <Route path='activity' element={<Hub.ActivityScreen />} />
            <Route path='student' element={<Hub.StudentScreen />}>
              <Route path='/student/learning_programs/:id' element={<LearningProgramShow />} />
              <Route path='/student/objective/:id/adaptive' element={<AdaptiveNavBar />}>
                <Route index element={<AdaptivePath />} />
                <Route path='/student/objective/:id/adaptive/skills' element={<AdaptiveSkills />} />
              </Route>

              <Route
                path='/student/objective/:id/positioning/:lspId'
                element={<PositioningResult />}
              />
              <Route
                path='/student/objective/:id/content/:contentId'
                element={<AdaptiveContent />}
              />
            </Route>
          </>
        ) : (
          <>
            <Route index element={<Lock.ProgressScreen />} />
            <Route path='engagement' element={<Lock.EngagementScreen />} />
            <Route path='activity' element={<Lock.ActivityScreen />} />
            <Route path='student' element={<Lock.StudentScreen />} />
          </>
        )}
      </Route>
      <Route path='/download' element={<ExportDownload />} />

      {/* Errors path handlers */}
      <Route path='401' element={<Errors.AccessDeniedScreen fill={fill} />} />
      <Route path='*' element={<Errors.NotFoundScreen fill={fill} />} />
      <Route path='500' element={<Errors.InternalErrorScreen fill={fill} />} />
      <Route path='error' element={<Errors.ErrorScreen fill={fill} />} />
      <Route path='domoscio' element={<Errors.FallbackScreen />} />
    </Routes>
  )
}

export default Navigation
