import React, { useContext } from 'react'
import { Loader, ListingSkillLevels } from '@domoscio/domoscio-ui'
import { getHistoryData } from './utils'
import { lxpHubTypes, lxpHubUtils } from '@domoscio/domoscio-sdk-js'
import { useFetchKnLevels } from '../../../../../hooks/useFetchKnLevels'
import { SkillsContext } from '../../../../../contexts/parameters/filterContext'
import { Client } from '../../../../../contexts/parameters'
import ListingSkillsPDFRenderer from './PDFRenderer/SkillDetailsRenderer/ListingSkillsPDFRenderer'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { useWindowSize } from '../../../../../hooks/useWindowSize'

export type Skill = {
  name: string
  initLevel?: number | undefined | null
  finalLevel?: number | undefined | null
  targetLevel: number
}

type Props = {
  evaluatedObjective: lxpHubTypes.ObjectiveStudent
  isOts: boolean
  learningSessionId: string | number | undefined
  screen?: 'positioning' | 'skills'
}

const MySkills = ({ evaluatedObjective, isOts, learningSessionId, screen = 'skills' }: Props) => {
  const client = useContext(Client.State)
  const { skills: knowledgeNodes } = useContext(SkillsContext)

  const { sortedQuizzes, quizCount, lastPozId } = getHistoryData(
    evaluatedObjective.learning_sessions
  )

  const { width: windowWidth } = useWindowSize()

  const isOnlyOneAttempt = evaluatedObjective.attempt_options.workflow === null

  let attemptNo: any = quizCount
  let lsForKns: any = lastPozId

  if (isOnlyOneAttempt || lxpHubUtils.isExternalAssessment(evaluatedObjective)) {
    lsForKns = learningSessionId
    attemptNo = undefined
  }
  if (screen === 'positioning') {
    lsForKns = learningSessionId
    attemptNo = sortedQuizzes.find(quiz => quiz.id === Number(learningSessionId))?.attempt_nb
  }

  const {
    skillLevels: lastPozLsKn,
    isLoading: isLoadingLastPozLsKn,
    error: errorLastPozLsKn
  } = useFetchKnLevels(lsForKns, true)

  const {
    skillLevels: firstPozLsKn,
    isLoading: isLoadingFirstPozLsKn,
    error: errorFirstPozLsKn
  } = useFetchKnLevels(sortedQuizzes[0]?.id, quizCount > 1)

  const getFirstPozLevel = (knId: number) => {
    return firstPozLsKn?.find(skill => skill.knowledge_node_id === knId)?.theta_final
  }

  if (isLoadingLastPozLsKn || (quizCount > 1 && isLoadingFirstPozLsKn)) {
    return <Loader></Loader>
  }
  if (errorLastPozLsKn || errorFirstPozLsKn) {
    return <h1>Something went wrong</h1>
  }
  const skillLevels = lastPozLsKn
    ? lastPozLsKn.map(elem => {
        return {
          ...elem,
          theta_initial:
            getFirstPozLevel(elem.knowledge_node_id) === 0
              ? 0
              : getFirstPozLevel(elem.knowledge_node_id) || elem.theta_initial
        }
      })
    : []

  const { datas: skills } = lxpHubUtils.getSkillLevels(
    evaluatedObjective,
    knowledgeNodes,
    skillLevels,
    attemptNo
  )
  const isCurrentLevel =
    (isOnlyOneAttempt || lxpHubUtils.isExternalAssessment(evaluatedObjective)) &&
    skills.every(skill => skill.finalLevel === null)

  const primaryColor = isOts ? '#6C51F6' : client.mqb_primary_color
  const generatePdfDocument = async () => {
    const { data: list } = lxpHubUtils.prepareSkillCardData(
      skills,
      evaluatedObjective.objective,
      windowWidth || 1000,
      isOts,
      evaluatedObjective
    )
    const blob = await pdf(
      <ListingSkillsPDFRenderer title={evaluatedObjective.name} skillData={list} isOts={isOts} />
    ).toBlob()
    saveAs(blob, evaluatedObjective.name.trim().replaceAll(' ', '_') || 'test')
  }

  return (
    <ListingSkillLevels
      primaryColor={primaryColor}
      isOts={isOts}
      objectiveStudent={evaluatedObjective}
      skillsData={skills}
      generatePdfDocument={generatePdfDocument}
      isDownloadable={screen === 'skills'}
      isCurrentLevel={isCurrentLevel}
    />
  )
}

export default MySkills
