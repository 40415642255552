/* eslint-disable @typescript-eslint/no-use-before-define */
const formatActivity = (datas: any) => {
  datas ??= [] // Logical nullish assignment

  // Format entry datas
  datas = datas.map(item => {
    const date = new Date(item?.date)
    const m = date.getMonth() + 1
    const y = date.getFullYear()
    return {
      name: m + '/' + y,
      value: item?.value || 0
    }
  })

  // Init today constant
  const today = new Date()
  const tMonth = today.getMonth() + 1
  const tYear = today.getFullYear()

  const getDueDate = (diffMonth: number) => {
    let dueMonth = tMonth - diffMonth
    const dueYear = dueMonth < 0 ? tYear - 1 : tYear
    dueMonth = dueMonth < 0 ? 12 + dueMonth : dueMonth

    return [dueMonth, dueYear]
  }

  // Case no data: fill with empty values for today
  if (datas.length === 0) {
    datas[0] = { name: tMonth + '/' + tYear, value: 0 }
  }

  const shadowDatas = datas.slice(0).reverse()
  datas = [{ name: tMonth + '/' + tYear, value: 0 }]

  // Fill missings index with empty data
  const index = 4 - datas.length
  for (let i = 0; i < index; i++) {
    datas = prependEmptyData(datas)
  }

  // Check data & filled to returned values
  shadowDatas.map((data: any) => {
    for (let i = 0; i < 4; i++) {
      const [dueMonth, dueYear] = getDueDate(i)
      const key = dueMonth + '/' + dueYear
      if (data.name === key) {
        datas[3 - i] = data
      }
    }

    return data
  })

  return datas.slice(-4) // 👈 get only last 4 months
}

// Fill datas with empty values
const prependEmptyData = (datas: any) => {
  let date = datas[0].name.split('/').map((d: string) => parseInt(d))
  if (date[0] === 1) {
    date = [12, date[1] - 1]
  } else {
    date = [date[0] - 1, date[1]]
  }
  return [
    {
      name: date.join('/'),
      value: 0
    },
    ...datas
  ]
}

export { formatActivity, prependEmptyData }
