import React from 'react'
import { NavButton } from '@domoscio/domoscio-ui'
import './ExportButton.scss'
import { MdClose } from 'react-icons/md'
import I18n from '../../../locales/I18n'

interface Props {
  closeModal: () => void
}

const ExportModal = ({ closeModal }: Props): any => {
  return (
    <div className='exportModal'>
      <h4>{I18n.t('export.modal.title')}</h4>
      <p>{I18n.t('export.modal.body')}</p>
      <NavButton handleClick={closeModal} isActive={true}>
        {I18n.t('export.modal.button')}
      </NavButton>
      <MdClose className='closeIcon' onClick={closeModal} />
    </div>
  )
}

// Tooltip is displayed when export button is disabled
const ExportTooltip = () => {
  return (
    <div className='exportTooltipWrapper'>
      <div className='exportTooltipContainer'>
        <span>{I18n.t('export.tooltip')}</span>
      </div>
    </div>
  )
}
ExportModal.Tooltip = ExportTooltip

export default ExportModal
