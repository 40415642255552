import { QuizCorrectionCard } from '@domoscio/domoscio-ui'
import React from 'react'
import { Theme } from '../../../../../../contexts/parameters'
import { useQuery } from 'react-query'
import { domoscioAccess } from '../../../../../../api/domoscio_access'
import { ResultType } from '../PositioningResult'

type Props = {
  question: any
  event: any
  onClick: (qtiData: any, event: ResultType) => void
}

const CorrectionCard = ({ question, onClick, event }: Props) => {
  const theme = React.useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary

  const isQtiExistInTheContent = question?.qti

  const { data, status } = useQuery({
    queryKey: ['QTI', question.id],
    queryFn: () =>
      domoscioAccess.getQti({
        cell_test_url: question.content_url
      }),
    enabled: !isQtiExistInTheContent
  })

  const handleClick = (qti: any) => {
    onClick(qti, event)
  }
  const score = event.payload.result.score.scaled

  return (
    <div className='listItem'>
      <QuizCorrectionCard
        score={score}
        qti={isQtiExistInTheContent || data}
        onClick={handleClick}
        status={isQtiExistInTheContent ? 'success' : status}
        primaryColor={primaryColor}
      />
    </div>
  )
}

export default CorrectionCard
