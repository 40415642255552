import React, { useState } from 'react'
import { useDomToolkit } from '../../../../../styles/dom-toolkit'
import './UnderlinedButton.scss'

interface ButtonProps {
  children: React.ReactNode | string
  isActive: boolean
  handleClick?: (e?: any) => void | {} | undefined
  isPrimary: boolean
}

const UnderlinedButton = ({ children, isActive, handleClick, isPrimary }: ButtonProps) => {
  const [isOver, setIsOver] = useState(false)
  const classes: any = useDomToolkit()

  const nonOtsClasses = `${classes.domTextPrimary} + ' active ' + ${classes.domBorderPrimary}`
  const otsClasses = 'otsUnderleinedButtonColor active'
  return (
    <div
      onMouseOver={() => setIsOver(true)}
      onMouseOut={() => setIsOver(false)}
      className='underlinedButtonWrapper'
      onClick={handleClick}
    >
      <button
        className={` underlinedButton ${
          isActive || isOver ? (isPrimary ? nonOtsClasses : otsClasses) : 'inactive'
        }`}
      >
        {children}
      </button>
    </div>
  )
}

export default UnderlinedButton
