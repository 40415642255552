import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'
import { useState, useEffect } from 'react'
import { domoscioAccess } from '../api/domoscio_access'

// Custom hook for extract correction from LearningSessionPositioning
export function useCorrection(learningSession: lxpHubTypes.LearningSession) {
  const [correction, setCorrection] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch ls associations events
        const events = await domoscioAccess.getEvents({ learning_session_id: learningSession?.id })
        const contentIds: any[] = events.map((event: any) => event.content_id)

        // Fetch events.contents
        // if contentsIds is empty don't fetch
        const contents =
          contentIds.length === 0 ? [] : await domoscioAccess.getContents({ id: contentIds })
        // Match events with contents
        const correction = contents
          .filter((content: any) => content.type === 'ContentEvaluation')
          .map((content: any) => {
            const event = events.find((e: any) => e.content_id === content.id)
            return { ...content, e: event }
          })

        setCorrection(correction as any)
      } catch (error) {
        setError(error as Error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return { correction, loading, error }
}
