/**
 * @file Manages the methods of the HomeNav component.
 */

import React from 'react'
import I18n from '../../locales/I18n'
import { NavButton } from '@domoscio/domoscio-ui'
import { useNavigate, useMatch, useResolvedPath } from 'react-router-dom'
import { useDomToolkit, Style } from '../../styles/dom-toolkit'
import { MdOutlineHelpOutline } from 'react-icons/md'
import './HomeNav.scss'
import { User } from '../../contexts/parameters'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { postTabUsageKpis } from './utils'

const getStyles = (classes: any) =>
  Style.create({
    HomeNavLink: {
      link: ['HomeNavLink', classes.domMarginLeft],
      divider: [
        classes.domBgGreyDk200,
        classes.domWidth60,
        classes.domMarginSmallTop,
        classes.domMarginLeftRemove
      ]
    },
    HomeNav: {
      nav: [classes.domFlex, classes.domFlex2, classes.domFlexCenter, classes.domMarginTop]
    }
  })

/**
 * Renders the HomeNavLink
 */
function HomeNavLink({ children, to, ...props }: any) {
  const classes: any = useDomToolkit()
  const styles = getStyles(classes)
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })
  const navigate = useNavigate()

  const handleClick = () => {
    postTabUsageKpis(to)
    navigate(to)
  }

  return (
    <div className={styles.HomeNavLink.link}>
      <NavButton isActive={match !== null} handleClick={handleClick}>
        {children}
      </NavButton>
    </div>
  )
}

/**
 * Renders the Home Navigation
 *
 * @category Components
 */
function HomeNav() {
  const classes: any = useDomToolkit()
  const styles = getStyles(classes)
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)

  const handleClickHelp = () => {
    userDispatch({
      onboarding: domUtils.updateTour(user.onboarding, 'analytics', { home: false })
    })
  }

  const links = ['progress', 'engagement', 'activity']
  return (
    <header>
      <nav className={classes.domFlex}>
        {/* Nav */}
        <div className={`${styles.HomeNav.nav} step1`}>
          {links.map((link, i) => (
            <HomeNavLink to={`/${link !== 'progress' ? link : ''}`} key={i} link={link}>
              {I18n.t(`screen.${link}`)}
            </HomeNavLink>
          ))}
        </div>
      </nav>
      <div onClick={() => handleClickHelp()} className='help'>
        <MdOutlineHelpOutline
          size={23}
          color='#8F9BBA
'
        />
      </div>
    </header>
  )
}

export default HomeNav
