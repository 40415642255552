import React from 'react'
import { StyleSheet, Path, Svg, Font } from '@react-pdf/renderer'
import Bold from '../Fonts/DM_Sans/DMSans-Bold.ttf'
import Medium from '../Fonts/DM_Sans/DMSans-Medium.ttf'
import Regular from '../Fonts/DM_Sans/DMSans-Regular.ttf'
import { MdOutlineArrowDropDown, MdOutlineStar } from 'react-icons/md'
import I18n from '../../../../../../../locales/I18n'

Font.register({
  family: 'Medium',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fonts: [
    {
      src: Regular
    },
    {
      src: Bold,
      fontWeight: 'bold'
    },
    {
      src: Medium,
      fontWeight: 500
    }
  ]
})

// Create styles
export const styles = StyleSheet.create({
  page: {
    position: 'relative',
    backgroundColor: '#f9f9f9',
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 30,
    fontFamily: 'Medium'
  },
  labels: {
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 30,
    paddingLeft: 10
  },
  labelWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  labelBox: {
    width: 8,
    height: 8,
    borderRadius: 2
  },
  labelLeftCol: {},
  objectiveName: {
    margin: 10,
    marginLeft: 10,
    fontSize: 14,
    marginBottom: 10,
    marginTop: 30,
    color: '#505050',
    fontWeight: 'bold'
  },
  card: {
    transform: 'scale(0.8)',
    backgroundColor: '#fff',
    borderRadius: 24,
    width: 270,
    height: 110,
    padding: 15,
    margin: 5,
    marginLeft: -20
  },
  skillNameWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  skillNameLeft: {
    width: '85%',
    height: 40
  },
  skillNameRight: {
    width: '15%',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  skillName: {
    fontSize: 12,
    fontWeight: 'bold',
    maxLines: 2,
    textOverflow: 'ellipsis'
  },
  cursorLine: {
    width: '100%',
    marginTop: -10
  },
  cursor: {
    width: 24,
    marginLeft: -10
  },
  barWrapper: {
    width: 240,
    position: 'relative',
    height: 12,
    borderRadius: 6,
    backgroundColor: '#ebebeb',
    overflow: 'hidden'
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
    borderRadius: '50%'
  },
  skillList: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  barScales: {
    position: 'relative',
    marginTop: 5,
    fontSize: 8,
    flexDirection: 'row',
    width: '100%'
  },
  scaleNumberWrapper: {
    position: 'absolute',
    alignItems: 'center',
    width: 4
  },
  scaleNumber: { fontSize: 6 },
  scaleNumberFirst: { marginLeft: 3 },
  scaleNumberLast: { marginLeft: -6 },
  scaleNumberMiddle: { marginLeft: -2 },
  scaleLine: {
    height: 4,
    width: 1,
    backgroundColor: 'gray',
    position: 'absolute',
    top: -5,
    left: 1.4
  }
})

interface Bar {
  value: number
  color: string
  styles?: any
}

interface Skill {
  isSucceed: boolean
  skillName: string
  multiProgressData: {
    scale: number
    targetValue: number
    values: Array<{ label: string; data: Bar }>
  }
}

export type ObjectiveAndSkills = {
  name: string
  skillData: Skill[]
}

type IconProps = {
  targetValue: number
  scale: number
}

export const TargetIcon = ({ targetValue, scale }: IconProps) => (
  <Svg
    style={{ marginLeft: targetValue === 0 ? -7.7 : targetValue === scale ? -17 : -13 }}
    viewBox='0 0 20 29'
    height='24'
    width='24'
  >
    <Path fill='none' d='M0 0h24v24H0V0z'></Path>
    <Path fill='#A7A7A7' d='M7 10l5 5 5-5H7z'></Path>
  </Svg>
)

export const Throphy = ({ color = 'bf4de8' }: { color?: string }) => {
  return (
    <Svg viewBox='0 0 24 24' aria-hidden='true' height='40' width='40'>
      <Path
        fill={color}
        fillRule='evenodd'
        d='M5.166 2.621v.858c-1.035.148-2.059.33-3.071.543a.75.75 0 00-.584.859 6.753 6.753 0 006.138 5.6 6.73 6.73 0 002.743 1.346A6.707 6.707 0 019.279 15H8.54c-1.036 0-1.875.84-1.875 1.875V19.5h-.75a2.25 2.25 0 00-2.25 2.25c0 .414.336.75.75.75h15a.75.75 0 00.75-.75 2.25 2.25 0 00-2.25-2.25h-.75v-2.625c0-1.036-.84-1.875-1.875-1.875h-.739a6.706 6.706 0 01-1.112-3.173 6.73 6.73 0 002.743-1.347 6.753 6.753 0 006.139-5.6.75.75 0 00-.585-.858 47.077 47.077 0 00-3.07-.543V2.62a.75.75 0 00-.658-.744 49.22 49.22 0 00-6.093-.377c-2.063 0-4.096.128-6.093.377a.75.75 0 00-.657.744zm0 2.629c0 1.196.312 2.32.857 3.294A5.266 5.266 0 013.16 5.337a45.6 45.6 0 012.006-.343v.256zm13.5 0v-.256c.674.1 1.343.214 2.006.343a5.265 5.265 0 01-2.863 3.207 6.72 6.72 0 00.857-3.294z'
      ></Path>
    </Svg>
  )
}
type StarSvgProps = {
  styles?: {}
  color?: string
}

export const StarSvg = ({ styles, color }: StarSvgProps) => (
  <Svg style={styles} width='103' height='103' viewBox='0 0 103 103'>
    <Path
      fillRule='evenodd'
      d='M102.021 41.8657V39.5675H101.995C101.432 37.5366 100.562 35.7195 98.8227 34.4635C97.4157 33.4748 95.8295 33.2076 94.2178 32.9671C86.7219 31.818 79.2005 30.6689 71.679 29.5733C70.1185 29.3328 69.044 28.5578 68.3276 27.0346C66.187 22.4312 64.0173 17.8479 61.8484 13.2662C60.492 10.401 59.1359 7.53641 57.7874 4.66786C56.3036 1.48788 53.8987 -0.195642 50.4706 0.0181383C47.5541 0.231919 45.5586 1.88872 44.2539 4.61442C43.1794 6.88586 42.1113 9.15061 41.0432 11.4154C39.9751 13.6801 38.907 15.9448 37.8325 18.2162C37.5768 18.7505 37.3202 19.2847 37.0635 19.8192C35.9081 22.2251 34.751 24.6343 33.6625 27.0614C32.9462 28.638 31.8205 29.3595 30.2088 29.6L30.2086 29.6C22.3035 30.7758 14.3983 31.9516 6.49319 33.1808C3.49996 33.6351 1.35098 35.3454 0.4044 38.4185C-0.56776 41.5183 0.250901 44.2173 2.45105 46.462L17.6474 61.9343C18.3638 62.6826 19.1057 63.4308 19.8476 64.179C20.8197 65.141 21.2035 66.2634 20.9732 67.6529C19.7197 75.2421 18.4661 82.8581 17.2637 90.474L17.2443 90.5952C16.9685 92.322 16.6811 94.1213 16.9311 95.8185C17.5963 100.522 22.5338 103.247 26.7295 101.43C27.7349 100.994 28.6981 100.456 29.6504 99.9228C29.9824 99.7371 30.313 99.5522 30.6437 99.3726C32.295 98.4663 33.9482 97.562 35.6018 96.6576C39.9941 94.2551 44.3888 91.8512 48.7565 89.4051C50.3171 88.55 51.6986 88.5233 53.2592 89.4051C60.2434 93.3066 67.2787 97.1546 74.2885 100.976C77.5376 102.766 80.7866 102.285 83.2938 99.6131C85.0846 97.7158 85.5963 95.391 85.1613 92.7989C84.9887 91.7441 84.8161 90.689 84.6434 89.6335C83.4598 82.399 82.2736 75.1494 81.068 67.9202C80.8122 66.3168 81.2471 65.0876 82.3472 63.992C84.743 61.5725 87.1287 59.1425 89.5132 56.7137C92.5015 53.67 95.4879 50.6281 98.4902 47.611C100.127 45.9542 101.56 44.2707 102.021 41.8657Z'
      fill={color}
    />
  </Svg>
)

export const getBarLabels = (isOts: boolean) => {
  return [
    {
      label: I18n.t('lxp_hub.base.scale_levels.initial'),
      icon: undefined,
      color: isOts ? '#70B7FF' : '#ffd166'
    },
    {
      label: I18n.t('lxp_hub.base.scale_levels.final_other'),
      icon: undefined,
      color: isOts ? '#2B71B8' : '#82B7EB'
    },
    {
      label: I18n.t('lxp_hub.base.scale_levels.expected'),
      icon: { name: MdOutlineArrowDropDown, size: 22 },
      color: '#A7A7A7'
    },
    {
      label: I18n.t('lxp_hub.base.scale_levels.achieved'),
      icon: { name: MdOutlineStar, size: 17 },
      color: '#32D296'
    }
  ]
}
