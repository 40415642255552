import html2canvas from 'html2canvas'
import { lxpHubUtils } from '@domoscio/domoscio-sdk-js'

export const exportLearningPath = async fileName => {
  const element = document.getElementsByClassName('learningPathElems')[0] as HTMLElement
  // eslint-disable-next-line new-cap
  // Dynamic import
  import('jspdf').then(({ jsPDF }) => {
    lxpHubUtils.preparePdfDownload(true)
    const downloadButton = document.querySelector(
      '.learningPathV2 .downloadBtnLine button'
    ) as HTMLElement

    try {
      html2canvas(element, {
        logging: true,
        allowTaint: false,
        useCORS: true,
        scale: 3
      }).then(canvas => {
        const imgWidth = 210
        const pageHeight = 300
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()
        let position = 0

        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        // Add new pages if canvas is heigher than page
        // For more information on these functions see  👉 https://github.com/parallax/jsPDF/issues/434
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save(fileName + '.pdf')
        lxpHubUtils.revertAllPdfDownload()
        downloadButton.style.cursor = 'pointer'
        downloadButton.style.pointerEvents = 'initial'
      })
    } catch (error) {
      console.log(error)
      lxpHubUtils.revertAllPdfDownload()
      downloadButton.style.cursor = 'pointer'
      downloadButton.style.pointerEvents = 'initial'
    }
  })
}
