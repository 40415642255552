import { domoscioAccess } from '../../../api/domoscio_access'

// Get objective scoped knowledge_nodes infos
export async function getAverageMemoryLevel(objectives: number[], studentUid: string) {
  return await domoscioAccess.getAverageMemoryLevel({
    objective_id: objectives,
    student_uid: studentUid,
    group_by: ['objective_id', 'knowledge_node_id']
  })
}

// Get student scoped objective_students infos
export async function getObjectiveStudents(studentUid: string) {
  return await domoscioAccess.getObjectiveStudents({
    student_uid: studentUid
  })
}
