/**
 * @file Manages the methods relative to the domToolkit setup in react web app.
 */

import { createUseStyles } from 'react-jss'
import { domToolkit, domToolkitRJS } from '@domoscio/domoscio-sdk-js'

export const toolkit = {
  ...domToolkit,
  ...domToolkitRJS
}

/**
 * Custom Hooks to use Toolkit with ThemeProvider.
 */
export const useDomToolkit = createUseStyles(theme => {
  const injectedTheme = domToolkit.isThemeValid(theme) ? domToolkit.initTheme(theme) : domToolkit
  return {
    ...injectedTheme,
    ...domToolkitRJS
  }
})

// usefull Style method
const iterate = obj => {
  Object.keys(obj || {}).forEach(key => {
    if (obj[key] instanceof Array && obj[key].length !== 0) {
      obj[key] = obj[key].classNames()
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      iterate(obj[key])
    }
  })
}

/**
 * Style object : format components style in pretty way like RN StyleSheet.create()
 */
export const Style = {
  create: style => {
    iterate(style)
    return style
  },
  classes: array => array.classNames()
}

// eslint-disable-next-line no-extend-native
Array.prototype.classNames = function () {
  const array = this
  return array.join(' ')
}
