import { useContext } from 'react'
import { Client, Session } from '../contexts/parameters'

export const useRiseUpClient = () => {
  const client = useContext(Client.State)
  const session = useContext(Session.State)
  const objectiveId = session?.options?.analytics?.objective_ids[0]

  if (!client) {
    throw new Error('useRiseUpClient must be used within an Parameters provider')
  }

  return {
    isRiseup: client.riseup_client,
    primaryColor: client.mqb_primary_color,
    objectiveId
  }
}
