import { useContext } from 'react'
import { useQuery } from 'react-query'
import { domoscioAccess } from '../api/domoscio_access'
import { ObjectiveStudent } from '../types/hub/objective'
import { LearningSession } from '../types/hub/learningSession'
import { ObjectivesFilterContext } from '../contexts/parameters/filterContext'

export const useFetchStudentLearningDatas = (
  studentUid: string | undefined,
  objectiveId: number[] | null
) => {
  const { objectiveFilters } = useContext(ObjectivesFilterContext)
  const body = { student_uid: studentUid } as any

  const lpsObjectives = objectiveFilters.objectivesOfSelectedExp
  // filtered by theme
  if (objectiveId) {
    body.objective_id = objectiveId
  }
  const { data: objectivesStudent } = useQuery(
    ['ObjectiveStudents', body],
    async (): Promise<ObjectiveStudent[]> => {
      return domoscioAccess.getObjectiveStudents(body)
    },
    {
      enabled: studentUid !== undefined
    }
  )
  const isEnabled = objectivesStudent !== undefined && objectivesStudent.length > 0
  const { data: learningSessions, status: lsFetchStatus } = useQuery(
    ['LsOfStudents', body],
    async (): Promise<LearningSession[]> => {
      return domoscioAccess.getLearningSessions(body)
    },
    {
      enabled: isEnabled
    }
  )

  const isLoading = lsFetchStatus === 'loading' || lsFetchStatus === 'idle'

  const assigneObjectiveInfos = (objectivesStudent: ObjectiveStudent[]) => {
    return objectivesStudent
      .map(os => {
        const obj = lpsObjectives.find(objective => objective.id === os.objective_id)
        const lsOfObjective = learningSessions?.filter(ls => ls.objective_id === obj?.id)
        return {
          ...os,
          objective: obj,
          name: obj?.name,
          image_url: obj?.image_url,
          learning_program_id: obj?.learning_program_id,
          learning_sessions: lsOfObjective
        }
      })
      .filter(el => el.objective)
  }

  return {
    objectivesStudent: assigneObjectiveInfos(objectivesStudent || []),
    learningSessions,
    isLoading
  }
}
