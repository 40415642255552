/* eslint-disable */

import React from 'react'
import { domoscioAccess } from '../../api/domoscio_access'
// import { updateUserJWT } from '../../storage/jwt'

// Context
const State = React.createContext()
const Dispatch = React.createContext()

const init = {}

// Reducer
const reducer = (state, nextState) => {
  const newState = {
    ...state,
    ...nextState
  }

  const stateAreEquals = JSON.stringify(state) === JSON.stringify(nextState)
  if (Object.keys(state).length !== 0 && !stateAreEquals) {
    let userParameters = (({ email, lang, mail_notification, onboarding }) => ({
      email,
      lang,
      mail_notification,
      onboarding
    }))(newState)

    if (!Object.keys(nextState).includes('email')) {
      delete userParameters.email
    }

    if (Object.keys(nextState).includes('mail_notification')) {
      userParameters = {
        ...userParameters,
        daily_reviews: nextState.mail_notification ? '1' : '0'
      }
    }

    if (Object.keys(nextState).includes('onboarding')) {
      domoscioAccess.updateUserParameter({ onboarding: nextState.onboarding })
    }

    domoscioAccess.updateStudent(userParameters).then(response => {
      const body = typeof response === 'object' ? response : JSON.parse(response)
      if (body?.message === 'User updated') {
        // updateUserJWT(userParameters)
        return { ...newState, ...{ updated: true } }
      } else {
        return { ...newState, ...{ updated: false } }
      }
    })
  }

  return newState
}

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, init)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export
export const User = {
  State,
  Dispatch,
  Provider
}
