import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MySkills from '../../subComponents/MySkills/MySkills'
import { ListingCorrection, Loader, QuizStats } from '@domoscio/domoscio-ui'
import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'
import I18n from '../../../../../locales/I18n'
import { useStudentDatas } from '../../StudentScreenHub'
import { getBadgeDatas } from './utils'
import { useCorrection } from '../../../../../hooks/useCorrection'
import { MdOutlineBuild, MdClose } from 'react-icons/md'
import CorrectionCard from './subComponents/CorrectionCard'
import CorrectionDisplay from './subComponents/CorrectionDisplay'
import './PositioningResult.scss'
export type ResultType = {
  answer: any
  score: {
    scaled: number
  }
}
export type Event = {
  // eslint-disable-next-line camelcase
  content_id: number
  payload: {
    result: ResultType
  }
}
const PositioningResult = () => {
  const [screen, setScreen] = useState<'tsr' | 'correction'>('tsr')
  const [correctionData, setCorrectionData] = useState<any>(null)

  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (!location.state.selectedObjective) {
      navigate('/')
    }
  }, [])

  const objectiveStudent = location.state.selectedObjective as lxpHubTypes.ObjectiveStudent
  const isOts = location.state.isOts
  const lspId = Number(params.lspId)
  const lsP = objectiveStudent.learning_sessions.find(ls => ls.id === lspId)
  const headerText = I18n.t('studentScreen.positioning.tsr_main')
  const { primaryColor } = useStudentDatas()

  const badgeDatas = getBadgeDatas(lsP, objectiveStudent) as any

  const { correction, loading, error } = useCorrection(lsP as lxpHubTypes.LearningSession)
  const isAutoPoz =
    objectiveStudent?.objective?.adaptive_options?.positioning_type === 'SelfPosSelection'

  const loadCorrection = (qtiData: string, userInput: ResultType) => {
    setCorrectionData({ qti: qtiData, userInput })
    setScreen('correction')
  }
  const answeredQuestion = lsP?.interaction_count || 0
  return (
    <div className={`positioningResult ${screen === 'correction' ? 'correctionMode' : ''}`}>
      {screen === 'tsr' ? (
        <div>
          <div
            className='tsrClose'
            onClick={() => navigate(`/student/objective/${objectiveStudent.objective_id}/adaptive`)}
          >
            <MdClose size={30} color='#777' />
          </div>
          <section className='statSection'>
            <QuizStats
              isDisplayTrophy={true}
              datas={badgeDatas}
              text={headerText}
              primaryColor={isOts ? '#6C51F6' : primaryColor}
              isOts={isOts}
            />
          </section>
          <section className='skillSection'>
            <MySkills
              evaluatedObjective={objectiveStudent}
              isOts={isOts}
              learningSessionId={Number(lspId)}
              screen={'positioning'}
            />
          </section>
          <section className='correctionSection'>
            {' '}
            {!isAutoPoz && answeredQuestion > 0 && (
              <ListingCorrection>
                <>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <div className='correctionFetchError'>
                      <MdOutlineBuild size={30} />
                    </div>
                  ) : (
                    correction.map((question: any, index) => {
                      return (
                        <CorrectionCard
                          key={index}
                          question={question}
                          event={question?.e}
                          onClick={loadCorrection}
                        />
                      )
                    })
                  )}
                </>
              </ListingCorrection>
            )}
          </section>
        </div>
      ) : (
        <CorrectionDisplay
          qtiContent={{ ...correctionData.qti, heading: objectiveStudent.name }}
          event={correctionData.userInput}
          setScreen={setScreen}
        />
      )}
    </div>
  )
}

export default PositioningResult
