/* eslint-disable camelcase */
import I18n from '../locales/I18n'
import { FilterDataCommun, Experience, FilterOption, Theme, Option } from '../types/filter'
import { BodyData } from '../contexts/parameters/filterBodyContext'

type Data = Array<FilterDataCommun>
export type SkillLevel = {
  theta_final: number | null
  theta_initial: number | null
  knowledge_node_id: number
}

export const getCacheBody = (body: Partial<BodyData>): Partial<BodyData> => {
  return {
    ...body,
    start_date: body.start_date?.split('T')[0],
    end_date: body.end_date?.split('T')[0]
  }
}
const millisToMinutesAndSeconds = (s: number) => {
  if (typeof s !== 'number') {
    return 0
  }
  if (s === 0) {
    return 0
  }
  const ms = s % 1000
  s = (s - ms) / 1000
  const secs = s % 60
  s = (s - secs) / 60
  const mins = s % 60
  const hrs = (s - mins) / 60

  const h = hrs > 0 ? hrs + 'h' : ''
  const min = mins > 0 ? mins : ''
  const sec = secs > 0 ? secs : ''

  if (h) {
    return h + min
  } else if (min) {
    return min + 'min' + sec
  } else {
    return secs + 'sec'
  }
}
// create options array to fit {value, id} type
const prepareOptions = (arr: Data) => {
  return arr.map((elem, index) => {
    return { id: elem?.id || index, uid: elem?.uid, value: elem?.name || '' }
  })
}

// Create groups array to fit {id, label, targets} type
const prepareLearningProgramGroups = (arr: Data) => {
  const learningPrograms: Array<string | number> = []
  const legacyLearningPrograms: Array<string | number> = []

  arr.forEach(lp => {
    if (lp.legacy_start || lp.legacy_end) {
      legacyLearningPrograms.push(lp.id)
    } else {
      learningPrograms.push(lp.id)
    }
  })

  return [
    { id: '1', label: I18n.t('screen.groups.experiences'), targets: learningPrograms },
    { id: '2', label: I18n.t('screen.groups.old_sessions'), targets: legacyLearningPrograms }
  ]
}

// get themes of selected experience or display all themes

const getThemesOfExperience = (
  experiences: Array<Experience>,
  objectives: Array<Theme>,
  selectedExperience: Array<FilterOption>
) => {
  const id =
    selectedExperience.length > 0 ? selectedExperience[0].id : prepareOptions(experiences)[0].id

  //  sort objectives by creating date desc
  const result: Array<Theme> = objectives
    .sort((a: Theme, b: Theme) => {
      const valueA = new Date(a.created_at).getTime()
      const valueB = new Date(b.created_at).getTime()
      if (valueA > valueB) {
        return 1
      } else {
        return -1
      }
    })
    .filter(elem => elem.learning_program_id === Number(id))
    .map(elem => {
      return {
        id: elem.id,
        name: elem.name,
        uid: elem.uid,
        nb_levels: elem.nb_levels,
        objects: elem.objects,
        learning_program_id: elem.learning_program_id,
        created_at: elem.created_at,
        attempt_options: elem.attempt_options,
        adaptive_options: elem.adaptive_options,
        image_url: elem?.image_url,
        validation_thresholds: elem?.validation_thresholds,
        estimated_positioning_time: elem.estimated_positioning_time,
        memorize_options: { workflow: elem.memorize_options.workflow }
      }
    })

  return result
}

const prepareThemes = (themes: Theme[]) => {
  const result = themes.map((theme, index) => {
    return { value: theme?.name || '', id: theme?.id || index, uid: theme?.uid }
  })
  if (result.length === 0) {
    result.unshift({
      value: I18n.t('screen.no_themes'),
      id: 'no',
      uid: 'no'
    })
  } else {
    result.unshift({
      value: I18n.t('screen.all_themes'),
      id: 'all',
      uid: 'all'
    })
  }
  return result
}

// function to create data of selected theme
const checkIfAllFetchesSuccessed = (
  data: any,
  selectedObjective: Option[],
  selectedExperience: Option[],
  status: 'success' | 'idle' | 'error' | 'loading'
) => {
  let isDataOk = false

  if (status === 'success') {
    isDataOk = Array.isArray(data?.body?.learning_programs)
  }

  // Selected theme uid
  const themeUid = selectedObjective[0]?.uid

  // wait for all api calls
  const isAllDataIsReady =
    selectedObjective?.length > 0 && isDataOk && selectedExperience.length > 0

  let dataOfSelectedTheme = data?.body

  if (isAllDataIsReady && data?.body?.learning_programs?.length > 0) {
    dataOfSelectedTheme =
      themeUid === 'all'
        ? data?.body?.learning_programs[0]?.values // 👈 data if all theme selected
        : data?.body?.objectives?.filter(elem => elem.uid === themeUid)[0]?.values //  👈 data of selected theme
  } else {
    dataOfSelectedTheme = null
  }

  // all themes vs one theme condition
  const isThemSelected = isAllDataIsReady && typeof selectedObjective[0].id === 'number'
  return { dataOfSelectedTheme, isThemSelected }
}

export {
  millisToMinutesAndSeconds,
  prepareOptions,
  prepareLearningProgramGroups,
  getThemesOfExperience,
  prepareThemes,
  checkIfAllFetchesSuccessed
}
