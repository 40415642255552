/* eslint-disable no-unused-vars */
import React from 'react'
import I18n from '../../../locales/I18n'
import { MdOutlineGroups, MdOutlineWhereToVote, MdOutlineLibraryBooks } from 'react-icons/md'
import { formatActivity } from '../../../utils/format'

// all props of all percentage components
export const prepareHubActivityData = (data: any) => {
  return [
    {
      absolute: {
        icon: <MdOutlineGroups />,
        containerClassName: 'small_container',
        data: data?.nbr_students || 0,
        title: I18n.t('progress.learners', { count: data?.nbr_students || 0 }),
        generalTooltipText: I18n.t('engagement.tooltips.learners')
      },
      evolution: {
        icon: <MdOutlineGroups />,
        containerClassName: 'large_container',
        generalTooltipText: I18n.t('activity.tooltips.learners_per_month'),
        title: I18n.t('progress.learners.other') + ' ' + I18n.t('activity.last_four_moths'),
        data: formatActivity(data?.monthly_students_subscription),
        labelTooltip: {
          plural: I18n.t('activity.learners.other').toLowerCase(),
          singular: I18n.t('activity.learners.one').toLowerCase()
        },
        isAnimationActive: true
      }
    },
    {
      absolute: {
        icon: <MdOutlineWhereToVote />,
        containerClassName: 'small_container',
        data: data?.nbr_total_positionings_done || 0,
        title: I18n.t('activity.hub_quiz_done', {
          count: data?.nbr_total_positionings_done || 0
        }),
        generalTooltipText: I18n.t('activity.tooltips.hub_quiz_done')
      },
      evolution: {
        icon: <MdOutlineWhereToVote />,
        containerClassName: 'large_container',
        generalTooltipText: I18n.t('activity.tooltips.hub_quiz_done_per_month'),
        title: I18n.t('activity.hub_quiz_done.other') + ' ' + I18n.t('activity.last_four_moths'),
        data: formatActivity(data?.monthly_positionings_done),
        labelTooltip: {
          plural: I18n.t('activity.hub_quiz_done.other').toLowerCase(),
          singular: I18n.t('activity.hub_quiz_done.one').toLowerCase()
        },
        isAnimationActive: true
      }
    },
    {
      absolute: {
        icon: <MdOutlineLibraryBooks />,
        containerClassName: 'small_container',
        data: data?.nbr_total_recommendations_seen || 0,
        title: I18n.t('activity.hub_reco_followed', {
          count: data?.nbr_total_recommendations_seen || 0
        }),
        generalTooltipText: I18n.t('activity.tooltips.hub_reco_followed')
      },
      evolution: {
        icon: <MdOutlineLibraryBooks />,
        containerClassName: 'large_container',
        generalTooltipText: I18n.t('activity.tooltips.hub_reco_followed_per_month'),
        title: I18n.t('engagement.reco_clicked') + ' ' + I18n.t('activity.last_four_moths'),
        data: formatActivity(data?.monthly_recommendations_seen),
        labelTooltip: {
          plural: I18n.t('activity.hub_reco_followed.other').toLowerCase(),
          singular: I18n.t('activity.hub_reco_followed.one').toLowerCase()
        },
        isAnimationActive: true
      }
    }
  ]
}
