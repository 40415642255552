/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { domoscioAccess } from '../../api/domoscio_access'
import { Client, Theme } from '../../contexts/parameters'
import { FilterBodyContext } from '../../contexts/parameters/filterBodyContext'
import {
  ExperiencesFilterContext,
  ObjectivesFilterContext
} from '../../contexts/parameters/filterContext'
import { useObjectiveUids } from '../../hooks/useObjective'
import { Loader, CardContainer, DataTable, NavButton } from '@domoscio/domoscio-ui'
import { MdChevronRight, MdExpandMore } from 'react-icons/md'
import { useDomToolkit } from '../../styles/dom-toolkit'
import I18n from '../../locales/I18n'
import './Table.css'
import { getCacheBody } from '../../utils/shared'
import { exportToExcel } from './utils'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'

type Props = {
  page:
    | 'LockProgression'
    | 'LockUsage'
    | 'LockEngagement'
    | 'HubUsage'
    | 'HubProgression'
    | 'HubEngagement'
}

const Table = ({ page }: Props) => {
  // 1. State init
  const [isShowDataTable, setIsShowDataTable] = useState(false)
  const { isRiseup } = useRiseUpClient()

  // 2. Context init
  const objectiveUids: any = useObjectiveUids()
  const datas = React.useContext(FilterBodyContext)
  const { selectedObjective } = React.useContext(ObjectivesFilterContext)
  const { selectedExperience } = React.useContext(ExperiencesFilterContext)
  const theme = React.useContext(Theme.State)
  const client = React.useContext(Client.State)
  const mode = client?.product || 'hub'
  const primaryColor = theme?.domBgPrimary
  const secondaryColor = theme?.domBgSecondary

  const classes: any = useDomToolkit()
  const navigate = useNavigate()
  const location = useLocation()

  // 3. Fetch chart url from domoscioAccess
  const url = 'getDatatable' + page
  const name = page + 'Table'
  // 3.A Format body query
  let body = {
    ...datas,
    objective_uid: objectiveUids[0],
    locale: I18n.locale,
    primary_color: primaryColor,
    secondary_color: secondaryColor
  } as any
  if (isRiseup) {
    body = {
      start_date: new Date('2010-01-01').toISOString(),
      end_date: new Date().toISOString(),
      riseup_client: true,
      locale: I18n.locale,
      primary_color: primaryColor
    }
  }

  const hasObjectiveContext =
    selectedObjective &&
    selectedObjective.length > 0 &&
    // @ts-ignore
    !['all', 'no'].includes(selectedObjective[0].id)

  // Remove objective_uid key if no one is picked in book filter or on activity pages
  if (!hasObjectiveContext || page === 'HubUsage' || page === 'LockUsage') {
    delete body.objective_uid
  }

  // wait for filters before fetching tables
  const bodyReady =
    datas.learning_program_uid.length > 0 &&
    objectiveUids.length !== 0 &&
    datas.student_group_uid.length !== 0

  // return given url with json extension
  const addJsonExtension = (url: string) => {
    const queryParamsIndex = url.indexOf('?')
    return queryParamsIndex === -1
      ? url + '.json'
      : url.slice(0, queryParamsIndex) + '.json' + url.slice(queryParamsIndex)
  }

  // 3.B Send query
  const getDatatable = () => {
    return useQuery(
      [name, getCacheBody(body)],
      async () => {
        const chart = await domoscioAccess[url](body)
        const chartUrl = addJsonExtension(chart.url)
        const response = await fetch(chartUrl, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })

        if (response.status === 200) {
          const resolved = await response.json()
          return resolved
        } else {
          return null
        }
      },
      {
        enabled: isRiseup ? true : bodyReady
      }
    )
  }

  // 4. Query datas assignment
  const { data: datatable, status, refetch, isFetching } = getDatatable()

  // 5. Scoped functions ⬇️
  const handleRowClicked = (student: any) => {
    const objectiveId = selectedObjective[0]?.id
    const from = location.pathname

    if (mode === 'lock') {
      navigate('/student', { state: student })
    } else if (objectiveId === 'all') {
      const experienceId = selectedExperience[0].id
      navigate(`/student/learning_programs/${experienceId}`, { state: { ...student, from } })
    } else {
      navigate(`/student/objective/${objectiveId}/adaptive`, { state: { ...student, from } })
    }

    return {}
  }

  // Add specific dt props by screen name
  let datatableProps = {}
  if (['LockEngagement', 'HubEngagement'].includes(page)) {
    datatableProps = {
      isColored: true,
      colorDescriptions: {
        green: I18n.t(`datatable.${mode}.green_description`),
        orange: I18n.t(`datatable.${mode}.orange_description${isRiseup ? 'v2' : ''}`),
        red: I18n.t(`datatable.${mode}.red_description${isRiseup ? 'v2' : ''}`)
      }
    }
  }

  const pathName = location.pathname
  const screenName = pathName === '/' ? 'progress' : pathName.replace('/', '')

  const handleClickExportDataTable = (data: any) => {
    // kpi logs (feature tracking)
    domoscioAccess.postKpiLog('SupervisorExportDatatable')

    exportToExcel(data.data, data.headers, I18n.t(`screen.${screenName}`))
  }

  // 6. Render
  return (
    <CardContainer
      containerClassName='full_container'
      style={{ overflow: 'hidden', marginBottom: 20 }}
    >
      <div className={'dataTableBottom'}>
        <div className={`${classes.domTextSecondary} step5`}>
          <div className='tableExpand' onClick={() => setIsShowDataTable(!isShowDataTable)}>
            <span className={'showMoreIcon'}>
              {!isShowDataTable ? (
                <MdChevronRight size={20} color='#686D76' />
              ) : (
                <MdExpandMore size={20} color='#686D76' />
              )}
            </span>
            <span>{I18n.t('screen.learners')}</span>{' '}
          </div>
        </div>
        {/* {JSON.stringify(datatable)} */}

        <div className={`content ${isShowDataTable ? 'displayTable' : 'hideTable'}`}>
          {status === 'success' && !isFetching ? (
            !datatable ? (
              <div className='errorDatatable'>
                <p>{I18n.t('error.dataTable')}</p>
                <NavButton handleClick={() => refetch()} isActive={true}>
                  {I18n.t('error.retry')}
                </NavButton>
              </div>
            ) : (
              <DataTable
                {...datatableProps}
                exportFileName={I18n.t(`screen.${screenName}`)}
                initialData={datatable.datas}
                options={datatable.options}
                onClickKey={'userDetails'}
                onClickRow={handleRowClicked}
                clickable={true}
                onClickExport={handleClickExportDataTable}
              />
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </CardContainer>
  )
}

export default Table
