import React from 'react'
import { Client } from './client'
import { FilterContextProvider } from './filterContext'
import { Session } from './session'
import { Theme } from './theme'
import { User } from './user'

const providers = [
  <FilterContextProvider key='filter' />,
  <Client.Provider key='client' />,
  <Session.Provider key='session' />,
  <Theme.Provider key='theme' />,
  <User.Provider key='user' />
]

const Parameters = ({ children: initial }) =>
  providers.reduce((children, parent) => React.cloneElement(parent, { children }), initial)

export { Parameters, Client, Session, Theme, User }
