/* eslint-disable camelcase */
import { lxpHubUtils, lxpHubTypes } from '@domoscio/domoscio-sdk-js'

export const getHistoryData = (lsPList: lxpHubTypes.LearningSession[]) => {
  // first sort quizzes list by date
  const sortedQuizzes = lxpHubUtils.getQuizDoneList(lsPList)
  const quizCount = sortedQuizzes.length
  const lastPozId = sortedQuizzes[quizCount - 1]?.id

  return { sortedQuizzes, quizCount, lastPozId }
}

export type SkillLevel = {
  theta_final: number | null
  theta_initial: number | null
  knowledge_node_id: number
}
