import { DomoscioAccess } from '@domoscio/domoscio-sdk-js'
import * as Sentry from '@sentry/react'

const access = sessionStorage.getItem('accesstoken')
const endpoint = process.env.REACT_APP_DOMOSCIO_ACCESS_URL
const env = process.env.REACT_APP_DOMOSCIO_ENV || 'dev'

const devMode = env === 'dev'
interface HttpCallBody {
  // eslint-disable-next-line camelcase
  access_token: string
  parameters: string
}

function reportingManager(error: any, body: HttpCallBody) {
  console.log(error)
  const message = error?.errorMessage
  // report timeout errors
  const isTimeoutMessage = message?.includes('timed out')
  if (message && isTimeoutMessage) {
    Sentry.configureScope(scope => {
      scope.setTransactionName('Api Error')
      Sentry.captureException(new Error('Domoscio Access Error'), {
        extra: {
          user: body?.access_token || 'token not found',
          parameters: body?.parameters || 'parameters not found'
        }
      })
    })
  }
}

export const domoscioAccess = new DomoscioAccess(
  access,
  endpoint,
  reportingManager,
  devMode,
  'supervisor'
)
