import { domoscioAccess } from '../../api/domoscio_access'

export const postTabUsageKpis = (to: string) => {
  const getPageName = () => {
    switch (to) {
      case '/':
        return 'SupervisorProgression'
      case '/engagement':
        return 'SupervisorEngagement'
      case '/activity':
        return 'SupervisorUsage'
    }
  }

  // kpi logs (feature tracking)
  domoscioAccess.postKpiLog(getPageName())
}
